import { useContext, useEffect } from 'react';

import { PermissionContext } from 'components/Global/AuthLayout';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import PaymentComponent from 'components/Payroll/Payments';

export const Payments = () => {
	const navigate = useNavigate();
	const context = useContext(PermissionContext);
	const check = context.allowedPermissions.includes('Allow_Initiate_Payment');

	useEffect(() => {
		if (!check) {
			navigate('/');
		}
	}, [check]);

	return <div>{check && <PaymentComponent />}</div>;
};
