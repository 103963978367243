import { Modal, Table, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Ellipse from 'components/Global/Ellipse';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { formatNumber, invalidText } from 'utils/utils';

type HistoryProps = {
	open: boolean;
	closeModal: () => void;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	allCompensationData: any;
	isLoading: boolean;
	fetchAllCompensationDetails: any;
};

// const ShareComponent = (data: any) => {
// 	const { record } = data;

// 	return (
// 		<Tooltip
// 			title={
// 				<>
// 					<p>EmployeeShare : {record.employeeShare} %</p>
// 					<p>EmployerShare : {record.employerShare} %</p>{' '}
// 				</>
// 			}
// 			placement="right"
// 		>
// 			{record.employerShare + record.employeeShare} %
// 		</Tooltip>
// 	);
// };

const HistoryModal = (props: HistoryProps) => {
	const {
		open,
		closeModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		allCompensationData,
		isLoading,
	} = props;
	const { data } = useSelector((state: any) => state?.userProfile);
	return (
		<>
			<Modal
				title="Compensation History"
				open={open}
				onCancel={closeModal}
				maskClosable={true}
				width={1000}
				closable={true}
				footer={null}
			>
				<Table
					dataSource={allCompensationData}
					pagination={{
						total: totalRecords,
						current: currentPage,
						pageSize: pageSize,
						showSizeChanger: false,

						pageSizeOptions: [10, 20, 50, 100, 200],
					}}
					onChange={tableChangeHandler}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Effective Date"
						dataIndex="effectiveDate"
						key="effectiveDate"
						sorter={true}
						className="bg-white"
						render={(text) => dayjs(text).format('DD/MM/YYYY')}
					/>
					<Column
						title="Compensation Rate"
						key="compensationRate"
						dataIndex="compensationRate"
						className="bg-white"
						width={200}
						render={(value) => `${value}%`}
					/>
					<Column
						title="Compensation Limit"
						key="compensationLimit"
						dataIndex="compensationLimit"
						className="bg-white"
						width={200}
						render={(value: string) => {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}}
					/>
					<Column
						title="Updated By"
						dataIndex="createdBy"
						key="createdBy"
						className="bg-white"
						render={(text) => text?.fullName}
					/>
					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						sorter={true}
						className="bg-white"
						render={(text) =>
							invalidText(text) ? (
								'-'
							) : (
								<Ellipse
									message={text}
									maxLength={20}
									key={text}
									tooltipMessage={text}
									isTooltip={true}
								/>
							)
						}
					/>
					<Column
						title="Attachment"
						dataIndex="attachment"
						key="attachment"
						className="bg-white"
						render={(text, record: any) => (
							<div>
								{record?.WorkersCompAttachment[0] ? (
									<a
										href={
											record.WorkersCompAttachment[0]
												.documentLink
										}
										target="_blank"
										rel="noopener noreferrer"
											style={{ cursor: 'pointer' 	,	opacity: 1}}
									>
										<Ellipse
											message={
												record.WorkersCompAttachment[0]
													.documentName
											}
											maxLength={20}
											key={
												record.WorkersCompAttachment[0]
													.documentName
											}
											tooltipMessage={
												record.WorkersCompAttachment[0]
													.documentName
											}
											isTooltip={true}
													isLink={true}
										/>

									</a>
								) : (
									'-'
								)}
							</div>
						)}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default HistoryModal;
