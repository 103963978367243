import { payrollApi } from 'Api/payroll';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber, toastText } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	setTotalNetPay: any;
};
const NetPayTable = (props: Props) => {
	const { selectedPayrollId, setTotalNetPay } = props;
	const [netPayData, setNetPayData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { data } = useSelector((state: any) => state?.userProfile);

	const { conversionRate, usdCurrencyToggle, isUsdCurrency } = useSelector(
		(state: any) => state.usdCurrencyToggle
	);

	const FormattedValue = (value: any) => {
		if (!usdCurrencyToggle && isUsdCurrency) {
			return formatNumber(Number(value), data?.CompanyCurrencies, true);
		} else {
			return formatNumber(
				Number(value * (conversionRate ?? 1)),
				data?.CompanyCurrencies
			);
		}
	};

	const fetchNetPay = async (payrollId: string) => {
		try {
			setIsLoading(true);
			const netPay = await payrollApi.getAllEmployeesNetPay(payrollId);
			setNetPayData(netPay.data.data);
			setTotalNetPay(netPay.data.data);
		} catch (err: any) {
			let message = 'Something went wrong in fetching Net Pay.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchNetPay(selectedPayrollId);
	}, [selectedPayrollId]);

	return (
		<>
			<div>
				<Table
					dataSource={netPayData}
					scroll={{ y: 'calc(90vh - 390px)' }}
					pagination={false}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="employeeCode"
						key="employeeCode"
						// sorter={true}
						width={'40%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								{record.field !== 'Total' ? (
									<>
										{' '}
										<span>
											{record.firstName} {record.lastName}
										</span>{' '}
										|{' '}
										<span className="color-purple">
											{record.employeeCode}
										</span>
									</>
								) : (
									''
								)}
							</>
						)}
					/>

					<Column
						title="Total Earnings"
						dataIndex="totalEarnings"
						key="totalEarnings"
						className="bg-white"
						width={'20%'}
						// sorter={true}
						render={(value: any, record: any) => {
							if (record.field === 'Total') {
								return '';
							} else {
								return FormattedValue(value);
							}
						}}
					/>
					<Column
						title="Total Deductions"
						key="totalDeductions"
						dataIndex="totalDeductions"
						className="bg-white"
						width={'20%'}
						// sorter={true}
						render={(value: any, record: any) => {
							if (record.field === 'Total') {
								return (
									<p className={styles['total-label']}>
										Cost to Company
										<br />
									</p>
								);
							} else {
								return FormattedValue(value);
							}
						}}
					/>
					<Column
						title="Total Pay"
						dataIndex="netPay"
						key="netPay"
						className="bg-white"
						width={'20%'}
						// sorter={true}
						render={(value: string, record: any) => {
							if (record.field === 'Total') {
								return (
									<p className={styles['total']}>
										{FormattedValue(value)}
									</p>
								);
							}
							return FormattedValue(value);
						}}
					/>
				</Table>
			</div>
		</>
	);
};
export default NetPayTable;
