import { postApi, getApi, getApiPDF } from 'apis';
const createNhima = async (data: any) => {
	return await postApi('/nhima', data, true);
};

const getLatestNhima = async () => {
	return await getApi('/nhima/latest');
};

const getNhimaHistory = async (params: any) => {
	return await getApi(`/nhima`, params);
};

const createCommunication = async (data: any) => {
	return await postApi('/nhimaCommunication/create', data, true);
};

const getAllCommunication = async (params: any) => {
	return await getApi(`/nhimaCommunication`, params);
};

const calculateNhima = async (data: any) => {
	return await postApi('/calculation/Nhima', data);
};

const getNhimaReports = async (params: any) => {
	return await getApi('/nhima/nhimaReports', params);
};

const createNhimaRecord = async (data: any, nhimaRecordId: string) => {
	return await postApi(`/nhima/record/${nhimaRecordId}`, data, true);
};

const downloadNhimaCommunication = async (communicationId: string) => {
	return await getApiPDF('/nhimaCommunication/download', {
		communicationId: communicationId,
	});
};

const downloadNhimaRecord = async (recordId: string) => {
	return await getApiPDF('/nhima/record/download', {
		nhimaRecordId: recordId,
	});
};

export const nhimaApi = {
	createNhima,
	getLatestNhima,
	getNhimaHistory,
	createCommunication,
	getAllCommunication,
	createNhimaRecord,
	getNhimaReports,
	calculateNhima,
	downloadNhimaCommunication,
	downloadNhimaRecord,
};
