/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AttendanceHeader from './attendanceReportHeader';
import AttendanceReportTable from './attendanceReportTable';
import { AppDispatch } from 'redux/store';
import { fetchPayPeriodAction } from '../../../redux/actions/payPeriodAction';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { toastText } from 'utils/utils';
import { reportApis } from 'Api/reports';
import { CloseSvg } from 'utils/svgs';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const AttendanceReportComponent = () => {
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');
	const [branchId, setBranchId] = useState('');
	const [departmentId, setDepartmentId] = useState('');
	const [year, setYear] = useState(dayjs());
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [totalRecords, setTotalRecords] = useState(0);
	const [isExportButtonLoading, setExportButtonisLoading] = useState(false);

	//attendance
	const [attendanceRows, setAttendanceRows] = useState<any[]>([]);
	const [attendanceColumns, setAttendanceColumns] = useState<any[]>([]);
	const [attendanceLoading, setAttendanceLoading] = useState(false);

	const dispatch = useDispatch<AppDispatch>();

	const { data: payPeriodData, isLoading } = useSelector(
		(state: any) => state.payPeriod
	);

	const navigate = useNavigate();

	const exportAttendanceReport = async () => {
		setExportButtonisLoading(true);
		try {
			const query: any = {
				branchId: branchId,
				departmentId: departmentId,
				payPeriodId: selectedPayPeriod,
				year: year.year(),
			};
			const response = await reportApis.exportAttendanceReport(query);

			// Find the label based on selectedPayPeriod
			const selectedPayPeriodData = payPeriodData.find(
				(period: any) => period.value === selectedPayPeriod
			);
			const label = selectedPayPeriodData
				? selectedPayPeriodData.label
				: '';

			const fileName = `${label}_attendance_report.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const pdfUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error: any) {
			console.log('error: ', error);
			let message = 'Something went wrong in fetching Attendance Report.';

			if (error?.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setExportButtonisLoading(false);
		}
	};

	const exportButton = [
		{
			text: 'Export',
			isLoading: isExportButtonLoading,
			className: 'btn-blue',
			// icon: <ExportSvg />,
			isSubmit: true,
			onclick: () => {
				exportAttendanceReport();
			},
			disabled: isExportButtonLoading,
		},
	];

	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const onYearChange = (value: any) => {
		setYear(value);
	};

	const handleChange = (value: any, isBranch: boolean) => {
		if (isBranch) {
			setBranchId(value);
		} else {
			setDepartmentId(value);
		}
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const generateColumns = (data: any) => {
		const columns = data.map((item: any) => {
			let title = '';
			let fixed = '';
			let width = 100; // Default width for other columns

			if (item.trim() === 'empName') {
				title = 'Employee Name';
				fixed = 'left';
				width = 130;
			} else if (item.trim() === 'empCode') {
				title = 'Employee Code';
				width = 120;
			} else {
				title = dayjs(item).format('DD');
				width = 70;
			}

			return {
				title: title,
				dataIndex: item,
				key: item,
				align: 'center',
				fixed: fixed,
				width: width,
				render: (text: any) => {
					if (text === 'H') {
						return <span style={{ color: 'green' }}>{text}</span>;
					} else if (text === 'A') {
						return <span style={{ color: 'red' }}>{text}</span>;
					} else {
						return <span>{text}</span>;
					}
				},
			};
		});
		return columns;
	};

	const fetchAttendanceData = async () => {
		try {
			setAttendanceLoading(true);
			if (!selectedPayPeriod) {
				return;
			}
			const query: any = {
				page: currentPage,
				branchId: branchId,
				departmentId: departmentId,
				payPeriodId: selectedPayPeriod,
				year: year.year(),
				// sortBy: sortField,
				// sortOrder: sortOrder,
				pageSize: pageSize,
			};
			const attendanceResponseData =
				await reportApis.getAttendanceReports(query);
			if (attendanceResponseData?.data?.data) {
				const response = attendanceResponseData.data.data;
				const columns = generateColumns(response.headers);

				setAttendanceRows(response.data);
				setAttendanceColumns(columns);
				setTotalRecords(response.count);
			}
		} catch (error: any) {
			let message = 'Something went wrong in getting attendance Report.';

			if (error?.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setAttendanceLoading(false);
		}
	};

	useEffect(() => {
		fetchAttendanceData();
	}, [
		currentPage,
		branchId,
		departmentId,
		selectedPayPeriod,
		sortField,
		sortOrder,
		pageSize,
	]);

	useEffect(() => {
		if (payPeriodData?.length > 1) {
			setSelectedPayPeriod(payPeriodData[1].value);
		}
	}, [year]);

	useEffect(() => {
		dispatch(fetchPayPeriodAction({ year: year.year() }));
	}, [year]);

	return (
		<>
			<div className={styles['attendance-report']}>
				<div className={styles['attendance-report__header']}>
					<div
						className={styles['attendance-report__header__title']}
					></div>
					<div
						className={styles['attendance-report__header__actions']}
						onClick={() => {
							navigate('/');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['attendance-report__table']}>
					{' '}
					<AttendanceHeader
						year={year}
						onYearChange={onYearChange}
						selectedPayPeriod={selectedPayPeriod}
						setSelectedPayPeriod={setSelectedPayPeriod}
						branchId={branchId}
						departmentId={departmentId}
						handleChange={handleChange}
						dropdownOptions={dropdownOptions}
						isLoading={isLoading}
						payPeriodData={payPeriodData}
						exportButton={exportButton}
					/>
					<AttendanceReportTable
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						tableChangeHandler={tableChangeHandler}
						isLoading={attendanceLoading}
						rows={attendanceRows}
						columns={attendanceColumns}
					/>
				</div>
			</div>
		</>
	);
};

export default AttendanceReportComponent;
