import { payrollApi } from 'Api/payroll';
import { Col, Row } from 'antd';
import { TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { EmployeeLeaveImage } from 'utils/svgs';
import { toastText, formatToDateOnly } from 'utils/utils';
import GlobalHeader from '../GlobalHeader';
import EmployeeLeavesTable from './Table';
import styles from './index.module.scss';
import { viewPayrollApi } from 'Api/payrollView';

type EmployeeLeavesProps = {
	payrollId: any;
	onlyView: boolean;
	headerData: any;
	setEmployeeLeaveData: (data: any) => void;
	EmployeeLeaveData: any;
};
const EmployeeLeavesComponent = (props: EmployeeLeavesProps) => {
	// const [employeeData, setEmployeeData] = useState<any[]>([]);
	const [employeeLeaveLoading, setEmployeeLeaveLoading] = useState(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const {
		payrollId,
		onlyView,
		headerData,
		setEmployeeLeaveData,
		EmployeeLeaveData,
	} = props;

	const fetchEmployeeLeave = async () => {
		console.log('onlyView: ', onlyView);
		try {
			const query = {
				payrollId: payrollId,
			};
			setEmployeeLeaveLoading(true);
			let leaveRequest;
			if (onlyView) {
				leaveRequest = await viewPayrollApi.getAllEmployeeLeaves(
					payrollId
				);
			} else {
				leaveRequest = await payrollApi.getEmployeesLeaves(query);
			}
			setEmployeeLeaveData(leaveRequest.data.data.data);
			setStartDate(formatToDateOnly(leaveRequest.data.data.startDate));
			setEndDate(formatToDateOnly(leaveRequest.data.data.endDate));
			// setTotalRecords(leaveRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching leave request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setEmployeeLeaveLoading(false);
		}
	};

	useEffect(() => {
		if (payrollId) {
			fetchEmployeeLeave();
		}
	}, [payrollId]);

	const handleCheckboxChange = (id: string, value: boolean) => {
		const updatedData = EmployeeLeaveData.map((item: any) => {
			if (item.id === id) {
				return {
					...item,
					deductFromSalary: value,
				};
			}
			return item;
		});
		setEmployeeLeaveData(updatedData);
	};

	return (
		<>
			<Row>
				<Col span={16}>
					<div className={styles['employee-leaves__table']}>
						<GlobalHeader
							headerData={headerData}
							toggleRequired={false}
						/>
						<EmployeeLeavesTable
							isLoading={employeeLeaveLoading}
							handleCheckboxChange={handleCheckboxChange}
							employeeData={EmployeeLeaveData}
							onlyView={onlyView}
							startDate={startDate}
							endDate={endDate}
						/>
					</div>
				</Col>
				<Col span={1}></Col>
				<Col span={7}>
					<div>
						<EmployeeLeaveImage />
					</div>
				</Col>
			</Row>
		</>
	);
};

export default EmployeeLeavesComponent;
