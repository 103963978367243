import GlobalHeader from '../GlobalHeader';
import TotalDeductionTable from './Table';
import styles from './index.module.scss';

type totalDeductionProps = {
	selectedPayrollId: string;
	setDeductionJSON: any;
	onlyView: boolean;
	headerData: any;
};
const TotalDeductionComponent = (props: totalDeductionProps) => {
	const { selectedPayrollId, setDeductionJSON, onlyView, headerData } = props;

	return (
		<div className={styles['total-deduction__table']}>
			<GlobalHeader headerData={headerData} toggleRequired={true} />
			<TotalDeductionTable
				setDeductionJSON={setDeductionJSON}
				selectedPayrollId={selectedPayrollId}
				onlyView={onlyView}
			/>
		</div>
	);
};

export default TotalDeductionComponent;
