import { Space, Table } from 'antd';
import { DeleteActionSvg, EditActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

const { Column } = Table;

type Props = {
	paygroupData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	setEditData: any;
	openDrawerHandler: any;
	setDrawerInfoHandler: any;
	showModal: any;
	isLoading: boolean;
};

const PayGroupTable = (props: Props) => {
	const {
		paygroupData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditData,
		showModal,
		isLoading,
	} = props;
	const permissionContext = useContext(PermissionContext);
	const isEdit =
		permissionContext.allowedPermissions.includes('Edit_Pay_Group');
	const isDelete =
		permissionContext.allowedPermissions.includes('Delete_Pay_Group');

	const editDataHandler = (selectedObj: any) => {
		// Extract values for the form or state update
		const currency =
			selectedObj.PayGroupCurrency[0]?.companyCurrency?.id || 'N/A';
		const departments =
			selectedObj.PayGroupDepartments.map(
				(dept: any) => dept.department.id
			) || [];
		const branches =
			selectedObj.PayGroupBranches.map(
				(branch: any) => branch.branch.id
			) || [];
		const employees =
			selectedObj?.PayGroupEmployee?.map((emp: any) => emp.employee.id) ||
			[];
		const employeesName =
			selectedObj?.PayGroupEmployee?.map(
				(emp: any) => emp.employee.firstName
			) || [];
		const name = selectedObj.name;
		const id = selectedObj.id;

		openDrawerHandler();
		setDrawerInfoHandler('Edit Pay Group');
		setEditData({
			id,
			name,
			currency,
			departments,
			branches,
			employees,
			employeesName,
		});
	};

	// Delete user data handler
	const deleteDataHandler = (userObject: any) => {
		setEditData(userObject);
		showModal();
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={paygroupData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Group Name"
					dataIndex="name"
					key="name"
					//	sorter={true}
					className="bg-white"
					width={'15%'}
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					width={'15%'}
					//	sorter={true}
					className="bg-white"
					render={(text, record: any) =>
						record?.PayGroupCurrency[0]?.companyCurrency
							?.currency || 'N/A'
					}
				/>
				<Column
					title="Department"
					dataIndex="departments"
					key="department"
					className="bg-white"
					width={'35%'}
					render={(text, record: any) =>
						record.PayGroupDepartments.map(
							(dept: any) => dept.department.name
						).join(' | ') || 'N/A'
					}
					//	sorter={true}
				/>
				<Column
					title="Branch"
					dataIndex="branches"
					key="branch"
					//	sorter={true}
					className="bg-white"
					render={(text, record: any) =>
						record?.PayGroupBranches.map(
							(branch: any) => branch.branch.name
						).join(' | ') || 'N/A'
					}
				/>
				{(isEdit || isDelete) && (
					<Column
						title="Action"
						key="action"
						className="bg-white"
						render={(_: any, data: any) => (
							<Space size={20}>
								{isEdit && (
									<div
										className="cursor-pointer  flex align-center justify-center"
										onClick={() => editDataHandler(data)}
									>
										<EditActionSvg />
									</div>
								)}
								{isDelete && (
									<div
										className="cursor-pointer flex align-center justify-center"
										onClick={() => deleteDataHandler(data)}
									>
										<DeleteActionSvg />
									</div>
								)}
							</Space>
						)}
					/>
				)}
			</Table>
		</div>
	);
};

export default PayGroupTable;
