import { Table } from 'antd';
import dayjs from 'dayjs';
import { formatNumber } from 'utils/utils';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const { Column, ColumnGroup } = Table;

type Props = {
	salaryData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	fetchRecords: any;
	isUSD?: boolean;
	isLoading: boolean;
};

const SalaryTable = (props: Props) => {
	const { data } = useSelector((state: any) => state?.userProfile);

	const {
		salaryData,
		totalRecords,
		currentPage,
		pageSize,
		isLoading,
		tableChangeHandler,
		isUSD,
	} = props;
 
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={salaryData}
				// scroll={{ y: 'calc(100vh - 200px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Monthly Salary"
					dataIndex="monthlySalary"
					className="bg-white"
					key="monthlySalary"
					render={(value: string) => {
						if (isUSD) {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies,
								true
							);
						} else {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}
					}}
					// sorter={true}
				/>
				<Column
					title="Pay Period Name"
					dataIndex="payperiod"
					key="payperiod"
					render={(value: any) => value?.name}
					width={'20%'}
					className="bg-white"
				/>
				<Column
					title="Mid Month Pay"
					dataIndex="midMonthSalary"
					key="midMonthSalary"
					className="bg-white"
				/>
				<Column
					title="Hourly Salary"
					dataIndex="hourlySalary"
					key="hourlySalary"
					className="bg-white"
					render={(value: string) => {
						if (isUSD) {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies,
								true
							);
						} else {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}
					}}
					// render={(value: string) => {
					// 	return formatNumber(
					// 		Number(value),
					// 		data?.CompanyCurrencies
					// 	);
					// }}
				/>
				<Column
					title="Effective Start Date"
					className="bg-white"
					dataIndex="effectiveStartDate"
					key="effectiveStartDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					// sorter={true}
				/>
				<Column
					title="Notes"
					dataIndex="notes"
					className="bg-white"
					key="notes"
					// sorter={true}
					render={(value: string) => (value ? value : '-')}
				/>
			</Table>
		</div>
	);
};

export default SalaryTable;
