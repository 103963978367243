/* eslint-disable react-hooks/exhaustive-deps */
import { loanRequestApi } from 'Api/loanRequest';
import { putApi } from 'apis';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import ConfirmModal from './ConfirmModal';
import NewLoanRequestModel from './NewLoanRequestModel';
import LoanRequestTableHeader from './Table/Header/index';
import LoanRequestTable from './Table/LoanRequestTable';
import UpdateLoanCreditModal from './UpdateLoanCreditModal';
import styles from './index.module.scss';

const LoanRequestComponent = () => {
	const navigate = useNavigate();

	const [loanRequestData, setLoanRequestData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [date, setDate] = useState<any>(dayjs());
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [supervisorFilterValue, setSupervisorFilterValue] = useState('');
	const [deleteLoanId, setDeleteLoanId] = useState<string | null>(null);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isNewLoanRequestModalOpen, setIsNewLoanRequestModalOpen] =
		useState(false);
	const [isConfirmLoanModal, setIsConfirmLoanModal] = useState(false);
	const [updateEffectiveDate, setUpdateEffectiveDate] = useState<any>(null);
	const [isUpdateLoanCreditModal, setIsUpdateLoanCreditModal] =
		useState(false);
	const [edit, setEdit] = useState<any>(null);

	const buttons = [
		{
			text: 'New Loan Request',
			isLoading: false,
			className: 'btn-blue',
			// icon: <AddSvg />,
			isSubmit: true,
			onclick: () => {
				setIsNewLoanRequestModalOpen(true);
			},
			disabled: isLoading,
		},
	];
	const cancelDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setDeleteLoanId(null);
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const handleSupervisorFilter = (value: string) => {
		setSupervisorFilterValue(value);
	};

	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const handleCancel = () => {
		setIsNewLoanRequestModalOpen(false);
	};

	const showDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const deleteHandler = async () => {
		if (deleteLoanId) {
			try {
				setIsDeleteLoading(true);
				await loanRequestApi.deleteLoanRequest(deleteLoanId);
				cancelDeleteModal();
				fetchLoanRequests();
			} catch (error) {
				toastText(
					'Something went wrong in delete Loan Request',
					'error'
				);
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchLoanRequests = async () => {
		try {
			const query = {
				page: currentPage,
				searchText: searchValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				month: dayjs(date).format('MM'),
				year: dayjs(date).format('YYYY'),
				supervisorId: supervisorFilterValue,
			};
			setIsLoading(true);
			const loanRequest = await loanRequestApi.getLoanRequests(query);
       console.log('loanRequest.data.data : ',loanRequest.data.data)
			setLoanRequestData(loanRequest.data.data);
			setTotalRecords(loanRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching loan request.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const updateLoanRequestStatus = async (id: string, newStatus: string) => {
		try {
			setIsLoading(true);
			const requestData = { id, status: newStatus };

			const response = await loanRequestApi.updateLoanRequestStatus(
				requestData.id,
				{ status: requestData.status }
			);

			if (response && response.data) {
				const updatedData = loanRequestData.map((item) =>
					item.id === id ? { ...item, status: newStatus } : item
				);
				setLoanRequestData(updatedData);
				toastText('Status updated successfully.', 'success');
			} else {
				toastText('Invalid response format', 'error');
			}
		} catch (error) {
			toastText('An error occurred while updating status.', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleOk = () => {
		setIsDeleteModalOpen(false);
		setDeleteLoanId(null);
	};
	const confirmLoanModal = () => {
		setIsConfirmLoanModal(false);
		setIsUpdateLoanCreditModal(true);
	};

	const cancelLoanModal = () => {
		setIsConfirmLoanModal(false);
	};

	const handleEdit = (loanObj: any) => {
		setIsNewLoanRequestModalOpen(true);
		setEdit(loanObj);
	};
	const confirmLoanModalClick = () => {
		setIsConfirmLoanModal(true);
	};

	// const updateLoanCredit = () => {
	// 	setIsUpdateLoanCreditModal(false);
	// 	setUpdateEffectiveDate(null);
	// };

	const cancelUpdateLoanCredit = () => {
		setIsUpdateLoanCreditModal(false);
		setUpdateEffectiveDate(null);
	};

	useEffect(() => {
		fetchLoanRequests();
	}, [
		currentPage,
		supervisorFilterValue,
		sortField,
		sortOrder,
		pageSize,
		searchValue,
		date,
	]);

	return (
		<div className="modal-animation">
			<div className={styles['time-logs']}>
				<div className={styles['time-logs__header']}>
					<div className={styles['time-logs__header__title']}>
						Loan Request
					</div>
					<div
						className={styles['time-logs__header__actions']}
						onClick={() => {
							navigate('/payroll-overview');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['time-logs__table']}>
					<LoanRequestTableHeader
						searchText={searchValue}
						handleSearch={handleSearch}
						handleSearchApi={handleSearchApi}
						handleDatePicker={handleDatePicker}
						dateValue={date}
						supervisorFilterValue={supervisorFilterValue}
						handleSupervisorFilter={handleSupervisorFilter}
						options={dropdownOptions?.supervisors}
						button={buttons}
					/>
					<LoanRequestTable
						tableData={loanRequestData}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						showDeleteModal={showDeleteModal}
						setDeleteLoan={setDeleteLoanId}
						handleEdit={handleEdit}
						fetchRecords={fetchLoanRequests}
						tableChangeHandler={tableChangeHandler}
						isLoading={isLoading}
						updateLoanRequestStatus={updateLoanRequestStatus}
						confirmLoanModal={confirmLoanModalClick}
						setUpdateEffectiveDate={setUpdateEffectiveDate}
					/>
				</div>
			</div>

			<NewLoanRequestModel
				isNewLoanRequestModalOpen={isNewLoanRequestModalOpen}
				handleCancel={handleCancel}
				fetchLoanRequest={fetchLoanRequests}
				edit={edit}
				setEdit={setEdit}
			/>

			<UpdateLoanCreditModal
				visible={isUpdateLoanCreditModal}
				onOk={cancelUpdateLoanCredit}
				onCancel={cancelUpdateLoanCredit}
				updateEffectiveDate={updateEffectiveDate}
				fetchLoanRequests={fetchLoanRequests}
			/>

			<ConfirmDelete
				isModalOpen={isDeleteModalOpen}
				handleCancel={cancelDeleteModal}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading}
				handleOk={handleOk}
			/>

			<ConfirmModal
				visible={isConfirmLoanModal}
				updateEffectiveDate={updateEffectiveDate}
				onOk={confirmLoanModal}
				onCancel={cancelLoanModal}
				content={`Are you sure Loan has been Credited to [${updateEffectiveDate?.employeeName} | ${updateEffectiveDate?.employeeCode}] ?`}
				okText="Yes"
				cancelText="No"
			/>
		</div>
	);
};

export default LoanRequestComponent;
