import { notificationApi } from 'Api/notification';
import { Checkbox, Table, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import RoleAssignModal from '../AssignRoleModal';
import styles from './index.module.scss';
import './index.scss';
import { EyeIcon } from 'utils/svgs';

const NotificationBody = () => {
	const [notificationData, setNotificationData] = useState<any[]>([]);
	const [isRoleModalOpen, setIsRoleModalOpen] = useState<boolean>(false);
	const [selectedNotification, setSelectedNotification] = useState<any>(null);
	const [roles, setRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [moduleName, setModuleName] = useState<string>('');
	const [saveLoading, setSaveLoading] = useState(false);

	const fetchNotificationConfig = async () => {
		try {
			setIsLoading(true);
			const notificationConfig =
				await notificationApi.getNotificationConfig();

			setNotificationData(notificationConfig.data.data);
		} catch (err: any) {
			let message =
				'Something went wrong in fetching notification config';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchNotificationConfig();
	}, []);

	const handleNotificationConfig = (
		checked: any,
		id: string,
		type: string
	) => {
		const _data = notificationData.map((item: any) => {
			if (item.id === id) {
				item[type] = checked;
				if (type === 'approvalRequired' && checked) {
					item['pushNotification'] = true;
				}
				if (type === 'pushNotification' && !checked) {
					item['approvalRequired'] = false;
				}
			}
			return item;
		});
		setNotificationData(_data);
	};

	const handleRoleSubmit = (notificationId: string, roleData: any) => {
		const data = notificationData.map((item: any) => {
			if (item.id === notificationId) {
				return {
					...item,
					roles: roleData,
				};
			} else {
				return item;
			}
		});
		setNotificationData(data);
		setIsRoleModalOpen(false);
	};

	const handleNotificationSubmit = async () => {
		try {
			setSaveLoading(true);
			await notificationApi.updateNotificationConfig({
				data: notificationData,
			});

			toastText('Updated notification successfully.', 'success');
			await fetchNotificationConfig();
		} catch (err: any) {
			let message =
				'Something went wrong in updating notification config';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setSaveLoading(false);
		}
	};

	const buttons = [
		{
			text: 'Edit',
			isSubmit: true,
			className: 'btn-blue',
			onclick: () => {
				handleNotificationSubmit();
			},
			disabled: isLoading,
			size: 'small',
			isLoading: saveLoading,
		},
	];

	return (
		<div className={styles['notification-settings']}>
			<Table
				dataSource={notificationData}
				pagination={false}
				loading={isLoading}
				scroll={{ y: 'calc(100vh - 280px)' }}
				rowKey="id"
			>
				<Column
					title="Module Name"
					dataIndex="parentModuleName"
					key="parentModuleName"
					className="bg-white"
					width={'15%'}
					render={(text: string, record: any, index: number) => {
						const previousRecord = notificationData[index - 1];
						if (
							!previousRecord ||
							previousRecord.parentModuleName !==
								record.parentModuleName
						) {
							return {
								children: text,
								props: {
									rowSpan: notificationData.filter(
										(item) =>
											item.parentModuleName ===
											record.parentModuleName
									).length,
								},
							};
						}
						return {
							children: text,
							props: {
								rowSpan: 0,
							},
						};
					}}
				/>
				<Column
					title="Sub Module Name"
					dataIndex="moduleName"
					key="moduleName"
					className="bg-white"
					width={'15%'}
				/>
				<Column
					title="Triggers"
					dataIndex="trigger"
					key="trigger"
					className="bg-white"
					width={'15%'}
					render={(_data: any, record: any) => {
						const activeTriggers = [];

						if (record.isAdd) activeTriggers.push('Add');
						if (record.isEdit) activeTriggers.push('Edit');
						if (record.isDelete) activeTriggers.push('Delete');

						return activeTriggers.join('/');
					}}
				/>

				<Column
					title="Push"
					dataIndex="pushNotification"
					key="pushNotification"
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<Checkbox
								checked={record}
								onChange={(e) =>
									handleNotificationConfig(
										e.target.checked,
										data.id,
										'pushNotification'
									)
								}
							/>
						);
					}}
				/>
				<Column
					title="Email"
					dataIndex="emailNotification"
					key="emailNotification"
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<Checkbox
								checked={record}
								onChange={(e) =>
									handleNotificationConfig(
										e.target.checked,
										data.id,
										'emailNotification'
									)
								}
							/>
						);
					}}
				/>
				<Column
					title="Approval"
					dataIndex="approvalRequired"
					key="approvalRequired"
					className="bg-white"
					render={(record: any, data: any) => {
						return (
							<Checkbox
								checked={data.approvalDisable ? false : record}
								disabled={data.approvalDisable}
								onChange={(e) =>
									handleNotificationConfig(
										e.target.checked,
										data.id,
										'approvalRequired'
									)
								}
							/>
						);
					}}
				/>
				<Column
					title="Approval Roles"
					dataIndex="roles"
					key="roles"
					className="bg-white"
					width={'30%'}
					render={(record: any, data: any) => {
						return (
							<div
								style={{
									display: 'flex',
									// justifyContent: 'space-between',
									gap: '10px',
									alignItems: 'center',
								}}
							>
								<RoleToolTip data={data.roles} />

								<p
									className="pointer color-blue"
									onClick={() => {
										setIsRoleModalOpen(true);
										setSelectedNotification(data);
										setRoles(data.roles);
										setModuleName(data.moduleName);
									}}
								>
									Assign Role
								</p>
							</div>
						);
					}}
				/>
			</Table>
			<Buttons buttons={buttons} />
			{isRoleModalOpen && (
				<RoleAssignModal
					isOpen={isRoleModalOpen}
					handleCancel={() => setIsRoleModalOpen(false)}
					selectedNotification={selectedNotification}
					handleRoleSubmit={handleRoleSubmit}
					roleData={roles}
					moduleName={moduleName}
				/>
			)}
		</div>
	);
};

export default NotificationBody;

const RoleToolTip = (data: any) => {
	const checkedRoles = data.data
		.filter((role: any) => role.isChecked)
		.map((role: any) => role.roleName)
		.join(', ');

	return (
		<div className="ant-tooltip-container">
			<Tooltip
				title={checkedRoles}
				// color="#fff"
				placement="top"
				overlayInnerStyle={{ width: 'max-content' }}
				className="ant-tooltip-paye"
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<EyeIcon />
					{/* <span>View Roles</span> */}
				</div>
			</Tooltip>
		</div>
	);
};
