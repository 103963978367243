import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { companySetup } from 'Api/companySetup';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	ApprovedByAccounts,
	CrossRejectedSvg,
	DeleteActionSvg,
	EditActionSvg,
	LoanProcessing,
	PendingForApproval,
} from 'utils/svgs';
import { formatNumber, toastText } from 'utils/utils';
import AddModal from '../AddModal';
import styles from './index.module.scss';
import { payrollApi } from 'Api/payroll';
import './index.scss';

type TableProps = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	showDeleteModal: any;
	setDeletePayroll?: any;
	fetchAllPayrolls?: any;
	//	fetchRecords: any;
};

const PayrollTable = (props: TableProps) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
		showDeleteModal,
		setDeletePayroll,
		fetchAllPayrolls,
	} = props;

	const navigate = useNavigate();

	const context = useContext(PermissionContext);
	const isEdit = context.allowedPermissions.includes('Edit_Run_Payroll');
	const isView = context.allowedPermissions.includes('View_Run_Payroll');
	const isDelete = context.allowedPermissions.includes('Delete_Run_Payroll');
	const isAddInitiatePayment = context.allowedPermissions.includes(
		'Add_Initiate_Payment'
	);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { data } = useSelector((state: any) => state?.userProfile);

	const [bankDetailsData, setBankDetailsData] = useState([]);
	const [isBankLoading, setIsBankLoading] = useState(false);
	const [bankFileLoading, setBankFileLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [selectedPayrollId, setSelectedPayrollId] = useState<string | null>(
		null
	);
	const [selectedPayrollCurrency, setSelectedPayrollCurrency] = useState<
		string | null
	>(null);

	const HandleViewModal = (id: string) => {
		navigate(`/run-payroll?payrollId=${id}&onlyView=true`);
	};

	// Function to open the modal
	const showModal = (payrollId:string) => {
		fetchCompanyBankDetails(payrollId);
		setIsModalVisible(true);
	};

	// Function to handle modal OK or close actions
	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		fetchAllPayrolls();
		setIsModalVisible(false);
	};

	const fetchCompanyBankDetails = async (payrollId:string) => {
		try {
			setIsBankLoading(true);
			const payrollData = tableData.find(
				(data: any) => data.id === payrollId
			);

			const isZMW = payrollData.isUSD !== 'ZMW' ? true : false;
			const bankDetails = await companySetup.getBankDetails(true, isZMW);
			setBankDetailsData(bankDetails.data.data);
		} catch (error: any) {
			let message = 'Something went wrong in fetching bank details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsBankLoading(false);
		}
	};

	const deleteDataHandler = (id: any) => {
		setDeletePayroll(id);
		showDeleteModal();
	};

	const handleModalOpen = (payrollId: string) => {
		showModal(payrollId);
		setSelectedPayrollId(payrollId);
	};

	const handleBankFileDownload = async (payrollId: string) => {
		try {
			setBankFileLoading((prev) => ({ ...prev, [payrollId]: true }));
			const response = await payrollApi.exportBnkCsv(payrollId);

			const payroll = tableData.find(
				(data: any) => data.id === payrollId
			);

			const fileName = `${payroll?.payPeriod}_bank_file.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const pdfUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error: any) {
			let message = 'Something went wrong in fetching bank file';

			if (error?.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setBankFileLoading((prev) => ({ ...prev, [payrollId]: false }));
		}
	};

	const handleBankDetails = async (data: any) => {
		setBankDetailsData(data);
	};

	return (
		<div className="dynamic-payroll-table">
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay period"
					dataIndex="payPeriod"
					key="payPeriod"
					sorter={false}
					className="bg-white"
					render={(text, record: any) => {
						if (record.status === 'APPROVED' && isView) {
							return (
								<span
									style={{ cursor: 'pointer', color: 'blue' }}
									onClick={() => HandleViewModal(record.id)}
								>
									{text}
								</span>
							);
						} else {
							return <span>{text}</span>;
						}
					}}
				/>
				<Column
					title="Pay group"
					dataIndex="payGroups"
					key="payGroups"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Currency"
					dataIndex="currency"
					key="currency"
					sorter={false}
					className="bg-white"
				/>

				<Column
					title="Pay Date"
					dataIndex="payDate"
					key="payDate"
					render={(value: string) =>
						dayjs(value).format('DD/MM/YYYY')
					}
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Submitted By"
					dataIndex="createdBy"
					key="createdBy"
					sorter={false}
					className="bg-white"
				/>
				<Column
					title="Total payable"
					dataIndex="totalPayable"
					key="totalPayable"
					sorter={false}
					className="bg-white"
					render={(value: string, record: any) => {
						if (record.currency === 'USD') {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies,
								true
							);
						} else {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies
							);
						}
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					render={(value) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						switch (value) {
							case 'PENDING':
								statusText = 'Pending';
								statusClass =
									styles['status-pending-for-approval'];
								StatusIcon = <PendingForApproval />;
								break;
							case 'APPROVED':
								statusText = 'Approved';
								statusClass =
									styles['status-approved-by-accounts'];
								StatusIcon = <ApprovedByAccounts />;
								break;
							case 'REJECTED':
								statusText = 'Rejected';
								statusClass =
									styles['status-rejected-by-accounts'];
								StatusIcon = <CrossRejectedSvg />;
								break;
							case 'DRAFT':
								statusText = 'Draft';
								statusClass = styles['status-draft'];
								StatusIcon = <LoanProcessing />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
						}

						return (
							<Tag
								bordered={false}
								className={`${styles['statusTag']} ${statusClass}`}
							>
								<span className={styles['statusIcon']}>
									{StatusIcon}
								</span>
								<span>{statusText}</span>
							</Tag>
						);
					}}
				/>
				<Column
					title="Action"
					key="action"
					className="bg-white"
					render={(_: any, record: any) => (
						<Space size={20}>
							{record.status !== 'APPROVED' &&
							isEdit &&
							record.status !== 'REJECTED' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{ fontSize: 18 }}
									onClick={() =>
										navigate(
											`/run-payroll?payrollId=${record?.id}`
										)
									}
								>
									<EditOutlined />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</p>
							)}

							{record.status !== 'APPROVED' &&
							isDelete &&
							record.status !== 'REJECTED' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{ fontSize: 18, cursor: 'pointer' }}
									// onClick={() => deleteDataHandler(record.id)}
									onClick={() => {
										deleteDataHandler(record.id); // Log before calling handler
									}}
								>
									<DeleteActionSvg />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</p>
							)}
						</Space>
					)}
				/>

				<Column
					title="Bank"
					dataIndex="bankFile"
					key="bankFile"
					sorter={false}
					className="bg-white"
					render={(_, record: any) => (
						<Tooltip title="Download bank file">
							<Buttons
								buttons={[
									{
										text: '',
										isLoading:
											bankFileLoading[record.id] || false,
										className: 'btn-blue',
										icon: <DownloadOutlined />,
										isSubmit: false,
										disabled: record.status !== 'APPROVED',
										onclick: () => {
											handleBankFileDownload(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						</Tooltip>
					)}
				/>
				<Column
					title="Payment"
					dataIndex="initiatePayment"
					key="initiatePayment"
					sorter={false}
					className="bg-white"
					render={(_, record: any) => {
						console.log('Ree:', record);
						return (
							<Buttons
								buttons={[
									{
										text: 'Initiate Payment',
										isLoading: false,
										className: 'btn-blue',
										isSubmit: false,
										disabled:
											{ record }.record?.status !==
												'APPROVED' ||
											!isAddInitiatePayment,
										onclick: () => {
											setSelectedPayrollCurrency(
												record.currency
											);
											handleModalOpen(record.id);
										},
										fontSize: '1.5rem',
										minWidth: '7rem',
										minHeight: '2rem',
									},
								]}
							/>
						);
					}}
				/>
			</Table>
			{selectedPayrollId && selectedPayrollCurrency && (
				<AddModal
					isOpen={isModalVisible}
					handleCancel={handleCancel}
					bankDetailsData={bankDetailsData}
					isBankLoading={isBankLoading}
					payRollId={selectedPayrollId}
					handleBankDetails={handleBankDetails}
					currency={selectedPayrollCurrency}
				/>
			)}
		</div>
	);
};

export default PayrollTable;
