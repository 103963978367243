import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { shiftsApi } from 'Api/shifts';
import DatePickerField from 'components/Global/DatePicker';
import { useEffect, useState } from 'react';
import {
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { Loader } from 'components/Global';
import TimePickerField from 'components/Global/TimePicker';
import dayjs from 'dayjs';
import SelectDropdown from 'components/Global/SelectDropdown';
import InputField from 'components/Global/InputField';
import InputHoursField from 'components/InputHoursField';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import Buttons from 'components/Global/Buttons';
import moment from 'moment';

type Props = {
	closeModal: () => void;
	isModalOpen: boolean;
	data: any;
};

const ViewShiftModal = (props: Props) => {
	const { isModalOpen, closeModal, data } = props;

	const [shiftDetails, setShiftDetails] = useState<any>(null);

	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				closeModal();
			},
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
		},
	];

	useEffect(() => {
		const fetchShiftDetails = async () => {
			try {
				setIsLoading(true);
				const response = await shiftsApi.getShiftDetails(data.id);
				setShiftDetails(response.data.data);
			} catch (error: any) {
				let message = 'Something went wrong in fetching shift details';

				if (error.response.data.message) {
					message = error.response.data.message;
				}
				toastText(message, 'error');
			} finally {
				setIsLoading(false);
			}
		};

		if (data.id) {
			fetchShiftDetails();
		}
	}, [data]);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	return (
		<>
			<Modal
				open={isModalOpen}
				onCancel={closeModal}
				okText={'Save'}
				closable={false}
				footer={null}
				className="document__popup"
				width="1000px"
				maskClosable={true}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>Shift Details</Title>
						<div className={styles['modal-header-filter']}></div>
					</div>
					{isLoading && <Loader />}
					{shiftDetails && !isLoading && (
						<div>
							<Row
								className={styles['form-container-card']}
								gutter={50}
							>
								<Col span={12} className={styles['col']}>
									<InputField
										name="startDate"
										value={dayjs(
											shiftDetails.startDate
										).format('MM/DD/YYYY')}
										label="Shift Start Date"
										required={false}
										disabled={true}
										isError={false}
										onChange={(value) => {}}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<InputField
										name="endDate"
										value={dayjs(
											shiftDetails.endDate
										).format('MM/DD/YYYY')}
										label="Shift End Date"
										required={false}
										disabled={true}
										isError={false}
										onChange={(value) => {}}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<InputField
										name="startTime"
										value={String(
											dayjs(
												shiftDetails.startTime
											).format('hh:mm A')
										)}
										label="Shift Schedule Start Time"
										required={false}
										helperText="Shift Schedule Start Time"
										onChange={(value) => {}}
										isError={false}
										disabled={true}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<InputField
										name="endTime"
										value={String(
											dayjs(shiftDetails.endTime).format(
												'hh:mm A'
											)
										)}
										label="Shift Schedule End Time"
										required={false}
										helperText="Shift Schedule End Time"
										onChange={(value) => {}}
										isError={false}
										disabled={true}
									/>
								</Col>

								<Col span={12} className={styles['col']}>
									<InputField
										name="shiftName"
										value={shiftDetails.shiftName}
										label="Shift Name"
										required={false}
										helperText="Shift name required"
										onChange={(value) => {}}
										isError={false}
										disabled={true}
									/>
								</Col>

								<Col span={12} className={styles['col']}>
									<InputHoursField
										value={shiftDetails.defaultRegularHours}
										onChange={(value) => {}}
										label="Standard Regular Hours"
										showLabel={true}
										required={false}
										name="defaultRegularHours"
										size="large"
										disabled={true}
										isError={false}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<InputHoursField
										value={minutesToTimeString(
											shiftDetails.defaultOverTimeHours
										)}
										onChange={(value) => {}}
										label="Standard Over Time Hours"
										showLabel={true}
										required={false}
										name="defaultOverTimeHours"
										size="large"
										disabled={true}
										isError={false}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<InputField
										name="shiftName"
										value={minutesToTimeString(
											shiftDetails.defaultRegularHours +
												shiftDetails.defaultOverTimeHours
										)}
										label="Standard Total Hours"
										required={false}
										helperText="Shift name required"
										onChange={(value) => {}}
										isError={false}
										disabled={true}
									/>
								</Col>
								<Col span={24} className={styles['col']}>
									<InputField
										name="notes"
										value={shiftDetails.notes as string}
										label="Notes"
										required={false}
										helperText="Notes required"
										onChange={(value) => {}}
										isError={false}
										disabled={true}
										rows={2}
									/>
								</Col>
							</Row>
						</div>
					)}
				</div>
				<Row className={styles['modal-buttons']}>
					<Buttons buttons={buttons} />
				</Row>
			</Modal>
		</>
	);
};

export default ViewShiftModal;
