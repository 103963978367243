import { InboxOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';

type ChequeProps = {
	onCancel: () => void;
};
const Cheque = ({ onCancel }: ChequeProps) => {
	const [form] = Form.useForm();

	const renderLabel = (label: string, required: boolean) => (
		<span>
			{label} {required && <span className={styles['required']}>*</span>}
		</span>
	);

	const buttons = [
		{
			text: 'Save',
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: '',
			isSubmit: false,
			disabled: false,
			onclick: onCancel,
			size: 'small',
			minHeight: '3.5rem',
			minWidth: '12rem',
		},
	];

	return (
		<div className={styles.form}>
			<div className={styles['form-container']}>
				<Form
					form={form}
					name="dependencies"
					autoComplete="off"
					layout="vertical"
					requiredMark={false}
				>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label={renderLabel('Cheque Name', true)}
								name="chequeName"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Cheque Name is required',
									},
									{
										pattern: /^[A-Za-z\s]+$/,
										message: 'Cheque Name must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								label={renderLabel('Cheque Number', true)}
								name="chequeNumber"
								labelCol={{
									className: styles['form-container-label'],
								}}
								rules={[
									{
										required: true,
										message: 'Cheque Number is required',
									},
									{
										pattern: /^\d+$/,
										message: 'Cheque Number must be valid',
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
					</Row>
					<Dragger>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							Click or drag file to this area to upload
						</p>
						<p className="ant-upload-hint">
							Support for a single or bulk upload.
						</p>
					</Dragger>
					<div className={styles['form-container-button']}>
						<Buttons buttons={buttons} />
					</div>
				</Form>
			</div>
		</div>
	);
};

export default Cheque;
