import GlobalHeader from '../GlobalHeader';
import WorkingHoursTable from './Table';
import styles from './index.module.scss';

type WorkingHoursProps = {
	selectedPayrollId: string;
	setWorkHoursJSON: any;
	onlyView: boolean;
	headerData: any;
};
const WorkHourComponent = (props: WorkingHoursProps) => {
	const { selectedPayrollId, setWorkHoursJSON, onlyView, headerData } = props;

	return (
		<div className={styles['work-hours__table']}>
			<GlobalHeader headerData={headerData} toggleRequired={true} />
			<WorkingHoursTable
				selectedPayrollId={selectedPayrollId}
				setWorkHoursJSON={setWorkHoursJSON}
				onlyView={onlyView}
			/>
		</div>
	);
};

export default WorkHourComponent;
