
import { SideDrawerWrapper } from 'components/Global';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import WorkerCompensation from './Header';
import HistoryModal from './CompensationHistoryModal';
import CompensationLatestTable from './CompensationTable';
import { WorkerTabProps } from './types';
import AddWorkerSideDrawer from './AddWorkerSideDrawer';
import { workersCompensationApi } from 'Api/workersCompensation';

const WorkerCompensationComponent = (props: WorkerTabProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [latestCompensationDetails, setLatestCompensationDetails] = useState<any>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isHistoryLoading, setIsHistoryLoading] = useState(false);
	const [allCompensationData, setAllCompensationData] = useState<any>([]);

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const sidebarDrawerOpen = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	const fetchLatestCompensationData = async () => {
		try {
			setIsLoading(true);
			const compensationData = await workersCompensationApi.getLatestCompensation();
			setLatestCompensationDetails(compensationData.data.data);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching worker compensation Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsLoading(false);
		}
	};
	const fetchAllCompensationDetails = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			setIsHistoryLoading(true);
			const allData = await workersCompensationApi.getCompensationHistory(query);
			const historyDetails = allData.data.data;

			setAllCompensationData(historyDetails);

			setTotalRecords(allData.data.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching history', 'error');
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsHistoryLoading(false);
		}
	};
	useEffect(() => {
		fetchLatestCompensationData();
	}, []);

	useEffect(() => {
		fetchAllCompensationDetails();
	}, [pageSize, currentPage, sortOrder, sortField]);

	return (
		<>
			<div>
				<WorkerCompensation
					sidebarDrawerOpen={sidebarDrawerOpen}
					CompensationExists={latestCompensationDetails.length > 0}
					isLoading={isLoading}
				/>
				<CompensationLatestTable
					compensationLatestData={latestCompensationDetails}
					isLoading={isLoading || isHistoryLoading}
					showModal={showModal}
					totalRecords={totalRecords}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={
						latestCompensationDetails.length > 0
							? 'Edit Compensation'
							: 'Add Compensation'
					}
					position="right"
					width="half"
				>
					<AddWorkerSideDrawer
						closeDrawerByAnimation={closeDrawerByAnimation}
						fetchLatestCompensationData={fetchLatestCompensationData}
						fetchAllCompensationDetails={fetchAllCompensationDetails}
					/>
				</SideDrawerWrapper>
			)}
			{isModalOpen && (
				<HistoryModal
					closeModal={closeModal}
					open={isModalOpen}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					allCompensationData={allCompensationData}
					isLoading={isHistoryLoading}
					fetchAllCompensationDetails={fetchAllCompensationDetails}
				/>
			)}
		</>
	);
};

export default WorkerCompensationComponent;
