import { Table } from 'antd';
import { payrollApi } from 'Api/payroll';
import { viewPayrollApi } from 'Api/payrollView';
import { getApiExcel } from 'apis';
import React, { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { toastText } from 'utils/utils';
import './index.scss';

const { Column } = Table;

type Props = {
	selectedPayrollId: string;
	onlyView: boolean;
	usdCurrency: boolean;
	handlePayslips: any;
	//onDownloadPayslips?: () => void;
};

const PaySlipTable: React.FC<Props> = (props: Props) => {
	const { selectedPayrollId, onlyView, usdCurrency, handlePayslips } = props;

	const [paySlipData, setPaySlipData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isDownLoading, setIsDownLoading] = useState(false);

	const rowSelection = {
		getCheckboxProps: (record: any) => ({
			disabled: record.name === 'Disabled User',
		}),
		selectedRowKeys,
		onChange: (keys: React.Key[], selectedRows: any[]) => {
			setSelectedRowKeys(keys);
			handlePayslips(keys);
		},
	};

	const getPaySlips = async (payrollId: string) => {
		try {
			setIsLoading(true);
			let response;
			if (usdCurrency) {
				response = await payrollApi.getPayslipsForUsd(payrollId);
			} else {
				response = await payrollApi.getPayslips(payrollId);
			}

			if (response) {
				setPaySlipData(response.data.data);
			}

			setIsLoading(false);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const getPaySlipForView = async (payrollId: string) => {
		try {
			setIsLoading(true);
			const response = await viewPayrollApi.getAllPaySlips(payrollId);
			if (response) {
				setPaySlipData(response.data.data);
			}
			setIsLoading(false);
		} catch (err: any) {
			let message = 'Something went wrong in fetching earnings.';

			if (err.response.data.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	// const downloadSelectedPayslips = async () => {
	// 	if (!selectedPayrollId || selectedRowKeys.length === 0) {
	// 		toastText(
	// 			'Payroll ID and selected payslip IDs are required',
	// 			'warning'
	// 		);
	// 		return;
	// 	}
	// 	try {
	// 		setIsDownLoading(true);

	// 		for (let i = 0; i < selectedRowKeys.length; i++) {
	// 			const payload = {
	// 				payrollId: selectedPayrollId,
	// 				payslipId: selectedRowKeys[i],
	// 			};

	// 			const response = await getApiExcel(
	// 				'/payroll/download/multiselected-payslip',
	// 				payload
	// 			);

	// 			if (response && response.data) {
	// 				const pdfBlob = new Blob([response.data], {
	// 					type: 'application/pdf',
	// 				});
	// 				const pdfUrl = URL.createObjectURL(pdfBlob);
	// 				const link = document.createElement('a');
	// 				link.href = pdfUrl;
	// 				link.setAttribute('download', 'payslips.pdf');
	// 				document.body.appendChild(link);
	// 				link.click();
	// 				document.body.removeChild(link);
	// 			}
	// 		}

	// 		toastText('Payslips downloaded successfully!', 'success');

	// 		setSelectedRowKeys([]);
	// 		//		setSelectedPayslipIds([]);
	// 	} catch (error: any) {
	// 		const message =
	// 			error.response?.data?.message || 'Failed to download payslips.';
	// 		toastText(message, 'error');
	// 	} finally {
	// 		setIsDownLoading(false);
	// 	}
	// };

	useEffect(() => {
		if (onlyView) {
			getPaySlipForView(selectedPayrollId);
		} else {
			getPaySlips(selectedPayrollId);
		}
	}, [selectedPayrollId]);

	return (
		<>
			<div className="pay-slip-table">
				<Table
					dataSource={paySlipData}
					className="pay-slips"
					scroll={{ y: 'calc(90vh - 360px)' }}
					pagination={false}
					rowKey={(record) => record.id}
					loading={isLoading}
					rowSelection={{ ...rowSelection, columnWidth: 1 }}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'45%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								<span>{record.employeeName}</span> |{' '}
								<span className="color-purple">
									{record.employeeId}
								</span>
							</>
						)}
					/>

					<Column
						title="Pay Slips"
						width={'45%'}
						className="bg-white"
						dataIndex="paymentSlips"
						key="paymentSlips"
						render={(text, record: any) => (
							<>
								<a
									href={record.documentLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FaFilePdf color="#d32f2f" size={20} />{' '}
								</a>
							</>
						)}
					/>
				</Table>
			</div>
		</>
	);
};

export default PaySlipTable;
