import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Table, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Column from 'antd/es/table/Column';
import { useNavigate } from 'react-router-dom';
type Props = {
	isLoading: boolean;
	handleCheckboxChange: (id: string, value: boolean) => void;
	employeeData: any;
	onlyView: boolean;
	startDate: string;
	endDate: string;
};

const EmployeeLeavesTable = (props: Props) => {
	const {
		isLoading,
		onlyView,
		handleCheckboxChange,
		employeeData,
		startDate,
		endDate,
	} = props;
	const navigate = useNavigate();

	const handleNavigateToLeaveRequest = (employeeId: string) => {
		navigate(
			`/leave-request?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
		);
	};

	return (
		<>
			<div>
				<Table
					dataSource={employeeData}
					pagination={false}
					scroll={{ y: 'calc(90vh - 360px)' }}
					rowKey={(record) => record.id}
					loading={isLoading}
				>
					<Column
						title="Employee Name and Code"
						dataIndex="optionalEmployeeId"
						key="optionalEmployeeId"
						width={'30%'}
						className="bg-white"
						// sorter={true}
						render={(text, record: any) => (
							<>
								<span>
									{record.firstName} {record.lastName}
								</span>{' '}
								|{' '}
								<span className="color-purple">
									{record.employeeCode}
								</span>
							</>
						)}
					/>

					<Column
						title="No. of Leaves"
						dataIndex="leaves"
						key="leaves"
						width={'20%'}
						className="bg-white"
						render={(text, record: any) => (
							<span>
								{record.leaves > 0 ? (
									<p
										style={{
											color: 'blue',
											cursor: 'pointer',
										}}
										onClick={() =>
											handleNavigateToLeaveRequest(
												record.id
											)
										}
									>
										{record.leaves}
									</p>
								) : (
									<span>{record.leaves}</span>
								)}
							</span>
						)}
					/>

					<Column
						title={
							<>
								Deducted from Salary{' '}
								<Tooltip title="Select this option if the leave taken by the employee should result in a deduction from their salary.">
									<ExclamationCircleOutlined />
								</Tooltip>
							</>
						}
						key="deductedFromSalary"
						width={'70%'}
						className="bg-white"
						render={(text, record: any) => (
							<>
								{record.leaves > 0 && (
									<>
										<Checkbox
											style={{ marginRight: '10px' }}
											checked={record.deductFromSalary}
											disabled={onlyView}
											onChange={(
												e: CheckboxChangeEvent
											) =>
												handleCheckboxChange(
													record.id,
													e.target.checked
												)
											}
										/>
									</>
								)}{' '}
								{record.deductFromSalary &&
									record.leaves > 0 && (
										<span>
											<span style={{ color: '#584495' }}>
												{[record.leaves]}
											</span>{' '}
											leave days have been credited back
											to the employee's leave balance.
										</span>
									)}
							</>
						)}
					/>
				</Table>
			</div>
		</>
	);
};
export default EmployeeLeavesTable;
