import { Col, Form, Input, Row } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useEffect, useState } from 'react';
import {
	hasFormError,
	invalidText,
	removeEmptyValues,
	toastText,
	validateFormData,
} from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import InputField from 'components/Global/InputField';
import InputNumberField from 'components/Global/InputNumberField';
import SelectDropdown from 'components/Global/SelectDropdown';
import { deductionFormula, deductionFormulaOptions } from 'constants/Data';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchCompanyWizard } from '../../../../../redux/actions/payrollSettingWizardAction';

const AddDeductionBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const { closeDrawerByAnimation, fetchDeductions, editSelected } = props;
	const [hasError, setHasError] = useState(false);

	const dispatch = useDispatch<AppDispatch>();

	const [deductionsDetails, setDeductionsDetails] = useState<any>({
		code: '',
		description: '',
		// formula: '',
		percentageOfDeduction: 0,
		amount: 0,
	});
	const [formError, setFormError] = useState<any>({
		code: false,
		description: false,
		formula: false,
		percentageOfDeduction: false,
		amount: false,
	});
	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
		{
			text: `${editSelected ? 'Update' : 'Save'}`,
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			font: '16px',
			minHeight: '42px',
			minWidth: '120px',
		},
	];
	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...deductionsDetails },
			{ ...formError }
		);

		checkFormError = {
			...checkFormError,
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			if (editSelected) {
				let data: any = {
					deductionCodeId: editSelected?.id,
					...deductionsDetails,
				};

				await updateDeduction(data);
			} else {
				await addDeduction(deductionsDetails);
				dispatch(fetchCompanyWizard());
			}
		}
	};

	const updateDeduction = async (deductionData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/deduction', deductionData);
			let message =
				response?.data?.message ||
				'Deduction details saved successfully';
			dispatch(fetchCompanyWizard());
			toastText(message, 'success');
			fetchDeductions();
			closeDrawerByAnimation();
		} catch (err: any) {
			let message = 'Something went wrong in updating deduction';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addDeduction = async (finalData: any) => {
		try {
			setLoading(true);
			await postApi('/deduction', finalData);
			fetchDeductions();
			closeDrawerByAnimation();
			toastText('Deduction created successfully', 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to deduction';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}
		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		OnChange(value, name);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string
	) => {
		setDeductionsDetails((prev: any) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	useEffect(() => {
		if (editSelected) {
			const object = removeEmptyValues(editSelected);
			setDeductionsDetails({ ...object });
		}
	}, [editSelected]);

	return (
		<>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['side-drawer-body']}>
				<Row gutter={5}>
					<Col span={24}>
						<InputField
							name="code"
							value={deductionsDetails.code}
							label="Code"
							required={true}
							helperText="Code is required"
							onChange={(value) => {
								handleChange(value, 'code', true);
							}}
							disabled={editSelected?.code}
							isError={formError.code}
						/>
					</Col>
				</Row>
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputField
							name="description"
							value={deductionsDetails.description}
							label="Description"
							required={true}
							helperText="Description is required"
							onChange={(value) => {
								handleChange(value, 'description', true);
							}}
							disabled={editSelected?.description}
							isError={formError.description}
						/>
					</Col>
				</Row>
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputNumberField
							name="percentageOfDeduction"
							value={deductionsDetails.percentageOfDeduction}
							label="Percentage"
							required={false}
							helperText="Percentage Of Deduction is required"
							onChange={(value) => {
								handleChange(
									value,
									'percentageOfDeduction',
									false
								);
							}}
							isError={formError.percentageOfDeduction}
						/>
					</Col>
				</Row>
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<InputNumberField
							name="amount"
							value={deductionsDetails.amount}
							label="Amount"
							suffix={'ZMW'}
							required={false}
							helperText="amount is required"
							onChange={(value) => {
								handleChange(value, 'amount', false);
							}}
							isError={formError.amount}
						/>
					</Col>
				</Row>
				<Row gutter={5} className="margin-top-10">
					<Col span={24}>
						<SelectDropdown
							placeholder="Formula"
							options={deductionFormulaOptions}
							value={deductionsDetails.formula}
							onChange={(value) => {
								handleChange(value, 'formula', false);
							}}
							size="large"
							required={false}
							helperText="formula is required"
							label="Formula"
							isError={formError.formula}
							labelSuffix={<InfoCircleOutlined />}
							labelSuffixContent={
								<div>
									<p
										style={{
											fontWeight: 500,
											fontSize: '18px',
										}}
									>
										Deduction Formulas
									</p>
									<ul>
										{Object.entries(deductionFormula).map(
											([code, formula]) => (
												<ul key={code}>
													<strong>{code}:</strong>{' '}
													{formula}
												</ul>
											)
										)}
									</ul>
								</div>
							}
						/>
					</Col>
				</Row>
				<div className={styles['buttons']}>
					<Buttons buttons={buttons} />
				</div>
			</div>
		</>
	);
};

export default AddDeductionBody;
