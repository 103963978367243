import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import SearchComponent from 'components/Global/SearchComponent';
import { currencyData } from 'constants/CurrencyData';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { payrollStatusOptions } from '../../../../../constants/payrollStatus';
import { fetchConstantDropdownAction } from '../../../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => Promise<void>;
	StatusFilter: string;
	handleStatusFilter: (value: string) => void;
	YearFilterValue: any;
	handleYear: (value: any) => void;
	PayPeriodFilterValue: string;
	handlePayPeriod: (value: string) => void;
	currencyValue: string;
	handleCurrency: (value: string) => void;
	button: any;
};

const PayrollHeader = (props: Props) => {
	const context = useContext(PermissionContext);

	const check = context.allowedPermissions.includes('Add_Run_Payroll');
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		YearFilterValue,
		handleYear,
		PayPeriodFilterValue,
		handlePayPeriod,
		StatusFilter,
		handleStatusFilter,
		currencyValue,
		handleCurrency,
		button,
	} = props;
	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const mappedCurrencyOptions = constantDropdownOptions?.currencyData?.map(
		(currency: any) => ({
			value: currency.value,
			label: `${currency.label} - ${
				currencyData[currency.label] || currency.label
			}`,
		})
	);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	return (
		<div className={styles['payrollOverview-header']}>
			<Space>
				<SearchComponent
					className={styles['payrollOverview-header-item']}
					placeHolder="Search here..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<Select
					style={{ width: 125 }}
					className={styles['payrollOverview-header-item']}
					value={StatusFilter}
					options={payrollStatusOptions}
					onChange={(value) => handleStatusFilter(value)}
					size="large"
					placeholder="Select Status"
				/>
				<div className={styles['year-pay-period-filter']}>
					<GlobalPayPeriod
						handlePayPeriod={(value) => handlePayPeriod(value)}
						selectedPayPeriod={PayPeriodFilterValue}
						isRow={false}
						handleSelectedYear={(value) => handleYear(value)}
						colSpan={24}
						colStyle={{ marginBottom: '1rem' }}
						payPeriodError={false}
						isYearRequired={false}
						isPayPeriodRequired={false}
						selectedYear={YearFilterValue}
						isPayrollOverview={true}
					/>
				</div>
				<Select
					style={{ width: 200 }}
					placeholder="Select Currency"
					size="large"
					value={currencyValue}
					// onSelect={(currency) => setSelectedCurrency(currency)}
					onChange={(value) => handleCurrency(value)}
					options={mappedCurrencyOptions}
				></Select>
			</Space>
			{check && <Buttons buttons={button} />}
		</div>
	);
};

export default PayrollHeader;
