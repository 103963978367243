/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import PaymentTableHeader from './Table/Header';
import PaymentTable from './Table/PaymentTable';
import styles from './index.module.scss';

const PaymentComponent = () => {
	const navigate = useNavigate();

	const [paymentData, setPaymentData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [date, setDate] = useState<any>(dayjs());
	const [supervisorFilterValue, setSupervisorFilterValue] = useState('');

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const handleSupervisorFilter = (value: string) => {
		setSupervisorFilterValue(value);
	};

	const dropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchPayments = async () => {
		try {
			const query = {
				page: currentPage,
				searchText: searchValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				month: dayjs(date).format('MM'),
				year: dayjs(date).format('YYYY'),
				supervisorId: supervisorFilterValue,
			};
			setIsLoading(true);
			// const loanRequest = await loanRequestApi.getLoanRequests(query);
			// console.log('loanRequest.data.data : ', loanRequest.data.data);
			// setLoanRequestData(loanRequest.data.data);
			// setTotalRecords(loanRequest.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching Payments.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPayments();
	}, [
		currentPage,
		supervisorFilterValue,
		sortField,
		sortOrder,
		pageSize,
		searchValue,
		date,
	]);

	return (
		<div className="modal-animation">
			<div className={styles['time-logs']}>
				<div className={styles['time-logs__header']}>
					<div className={styles['time-logs__header__title']}>
						Payments
					</div>
					<div
						className={styles['time-logs__header__actions']}
						onClick={() => {
							navigate('/payroll-overview');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['time-logs__table']}>
					<PaymentTableHeader
						searchText={searchValue}
						handleSearch={handleSearch}
						handleSearchApi={handleSearchApi}
						handleDatePicker={handleDatePicker}
						dateValue={date}
						supervisorFilterValue={supervisorFilterValue}
						handleSupervisorFilter={handleSupervisorFilter}
						options={dropdownOptions?.supervisors}
						// button={buttons}
					/>
					<PaymentTable
						tableData={paymentData}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						fetchRecords={fetchPayments}
						tableChangeHandler={tableChangeHandler}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default PaymentComponent;
