import React, { FC, useState, useEffect } from 'react';
import { Table, Modal, Form, Input, Select, Space, Button } from 'antd';
import Header from './Header';
import { HistoryOutlined } from '@ant-design/icons';
import HistoryModal from './HistoryModal';
import styles from './index.module.scss';
import { postApi, getApi } from 'apis';
import { ActiveRule, CloseSvg, InactiveRule } from 'utils/svgs';
import { invalidText, toastText } from 'utils/utils';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import dayjs from 'dayjs';
import './index.scss';
import Ellipse from 'components/Global/Ellipse';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchCompanyWizard } from '../../../../redux/actions/payrollSettingWizardAction';
const { Option } = Select;
const { TextArea } = Input;

interface RecordType {
	id: string;
	day: number;
	updatedBy: string;
	createDate: string;
	comment: string;
	status: string;
}

interface HistoryType {
	day: number;
	updatedBy: string;
	comment: string;
	createDate: string;
}

const MidMonthPayConfig = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [historyModalVisible, setHistoryModalVisible] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState<RecordType | null>(
		null
	);
	const [isModalLoading, isSetModalLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [historyData, setHistoryData] = useState<HistoryType[]>([]);
	const [historyLoading, setHistoryLoading] = useState<boolean>(false);

	const dispatch = useDispatch<AppDispatch>();

	const getDayOrdinal = (day: number) => {
		if (day === 1 || day === 21 || day === 31) {
			return 'st of Month';
		} else if (day === 2 || day === 22) {
			return 'nd of Month';
		} else if (day === 3 || day === 23) {
			return 'rd of Month';
		} else {
			return 'th of Month';
		}
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchMostRecentData = async () => {
		try {
			setIsLoading(true);
			const response = await getApi('/midMonthPayConfig/mostRecent');

			const latestData = response.data.data;

			setData(latestData);
		} catch (error) {
			toastText('error', 'Failed to Fetch Mid-Month Data !');
		} finally {
			setIsLoading(false);
		}
	};

	const fetchHistoryData = async () => {
		try {
			setHistoryLoading(true);
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			const response = await getApi('/midMonthPayConfig', query);

			const entries: RecordType[] = response.data.data;
			setHistoryData(entries);
			setTotalRecords(response.data.total);
		} catch (error) {
			toastText('Failed to Fetch History Data!', 'error');
		} finally {
			setHistoryLoading(false);
		}
	};

	const showModal = () => {
		if (data.length > 0) {
			const latestEntry = data[0];
			form.setFieldsValue({
				day: latestEntry.day,
				comment: latestEntry.comment,
			});
		} else {
			form.resetFields();
		}
		setIsModalVisible(true);
	};

	const handleSubmit = async () => {
		try {
			isSetModalLoading(true);
			const values = await form.validateFields();

			const existingData = data.find(
				(entry: RecordType) =>
					entry.day === values.day && entry.comment === values.comment
			);

			if (existingData) {
				toastText(
					'Data with Same Day and Comment Already Exists!',
					'error'
				);
				setIsModalVisible(false);
				return;
			}

			const formData = {
				day: values.day,
				comment: values.comment,
				status: true,
			};

			const response = await postApi('/midMonthPayConfig', formData);
			dispatch(fetchCompanyWizard());
			const createdData = response.data.data;

			const combinedTableData: any = {
				id: createdData.id,
				day: createdData.day,
				updatedBy: createdData.updatedBy.fullName,
				createDate: createdData.createdAt,
				comment: createdData.comment,
				status: createdData.status,
			};

			setData([combinedTableData]);
			toastText(
				'Mid-Month Date setup has been completed successfully!',
				'success'
			);

			fetchMostRecentData();
			setIsModalVisible(false);
		} catch (error) {
			toastText('Failed to Save Mid-Month Pay!', 'error');
		} finally {
			isSetModalLoading(false);
		}
	};

	const handleCancel = () => {
		form.resetFields();
		setIsModalVisible(false);
	};

	const handleHistoryClick = async (record: RecordType) => {
		setSelectedRecord(record);
		await fetchHistoryData();
		setHistoryModalVisible(true);
	};

	useEffect(() => {
		fetchMostRecentData();
	}, []);

	useEffect(() => {
		fetchHistoryData();
	}, [historyModalVisible, currentPage, pageSize, sortField, sortOrder]);

	const columns = [
		{
			title: 'Mid Month Day',
			dataIndex: 'day',
			key: 'day',
			render: (day: number) => (
				<>
					{day}
					{getDayOrdinal(day)}
				</>
			),
		},
		{
			title: 'Updated By',
			dataIndex: 'updatedBy',
			key: 'updatedBy',
			render: (text: any, record: RecordType) => text?.fullName,
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: string) =>
				dayjs(createdAt).format('DD/MM/YYYY'),
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			render: (text: string) =>
				invalidText(text) ? (
					'-'
				) : (
					<Ellipse
						message={text}
						maxLength={20}
						key={text}
						tooltipMessage={text}
						isTooltip={true}
					/>
				),
		},

		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status: boolean) => (
				<span className={styles['status-cell']}>
					{status !== false ? (
						<>
							<span className={styles['status-icon']}>
								<ActiveRule />
							</span>{' '}
							Active
						</>
					) : (
						<>
							<span className={styles['status-icon']}>
								<InactiveRule />
							</span>{' '}
							Inactive
						</>
					)}
				</span>
			),
		},
	];

	if (data.length > 0) {
		columns.push({
			title: 'History',
			dataIndex: 'action',
			key: 'action',
			render: (text: string, record: RecordType) => (
				<Space size="middle">
					<Button
						type="link"
						icon={<HistoryOutlined />}
						onClick={() => handleHistoryClick(record)}
					></Button>
				</Space>
			),
		});
	}

	return (
		<div>
			<Header showModal={showModal} isLoading={isLoading} />{' '}
			<Table
				columns={columns}
				pagination={false}
				dataSource={data}
				rowKey="key"
				loading={isLoading || historyLoading}
			/>
			<Modal
				className="setUpModal"
				title={
					<div>
						<h3>{data.length > 0 ? 'Edit' : 'Create'}</h3>
						<div onClick={handleCancel}>
							<CloseSvg />
						</div>
					</div>
				}
				closable={false}
				open={isModalVisible}
				footer={[
					<Button
						key="cancel"
						onClick={handleCancel}
						disabled={isModalLoading}
					>
						Cancel
					</Button>,
					<Button
						className="setupButton"
						loading={isModalLoading}
						key="save"
						type="primary"
						onClick={handleSubmit}
					>
						{data.length > 0 ? 'Edit' : 'Create'}
					</Button>,
				]}
				style={{ position: 'absolute', top: 200, right: 20 }}
			>
				<Form form={form} layout="vertical" requiredMark="optional">
					<Form.Item
						name="day"
						label={
							<span>
								Mid Month Day{' '}
								<span style={{ color: 'red' }}>*</span>
							</span>
						}
						rules={[
							{ required: true, message: 'Please select a day' },
						]}
					>
						<Select placeholder="Select a day">
							{Array.from({ length: 31 }, (_, i) => i + 1).map(
								(day) => (
									<Option key={day} value={day}>
										{day} {getDayOrdinal(day)}
									</Option>
								)
							)}
						</Select>
					</Form.Item>
					<Form.Item
						name="comment"
						label={
							<span>
								Comment <span style={{ color: 'red' }}>*</span>
							</span>
						}
						rules={[
							{
								required: true,
								message: 'Please provide a reason',
							},
						]}
					>
						<TextArea rows={4} />
					</Form.Item>
				</Form>
			</Modal>
			{/* History Modal */}
			{selectedRecord && (
				<HistoryModal
					isVisible={historyModalVisible}
					onClose={() => setHistoryModalVisible(false)}
					record={selectedRecord}
					historyData={historyData}
					page={currentPage}
					loading={historyLoading}
					pageSize={pageSize}
					tableChangeHandler={tableChangeHandler}
					fetchRecords={fetchHistoryData}
					totalRecords={totalRecords}
				/>
			)}
		</div>
	);
};

export default MidMonthPayConfig;
