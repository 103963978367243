import { InboxOutlined } from '@ant-design/icons';
import { Col, Form, Modal, Row } from 'antd';
import Upload, { UploadChangeParam, UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { leaveRequestApi } from 'Api/leaveRequest';
import { leaveManagementApi } from 'Api/masters/leave-management';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import SelectDropdown from 'components/Global/SelectDropdown';
import TextareaFieldInput from 'components/Global/textareaField';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	formatToDateOnly,
	getFinancialYearRange,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
import { AddLeaveRequestProps } from './type';
import { companySetup } from 'Api/companySetup';
import InputField from 'components/Global/InputField';

const AddLeaveRequestModal: React.FC<AddLeaveRequestProps> = (props) => {
	const {
		handleCancel,
		isAddLeaveRequestModalOpen,
		fetchLeaveRequest,
		edit,
		setEdit,
	} = props;

	const dispatch = useDispatch<AppDispatch>();

	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [employeeMaxLeaves, setEmployeeMaxLeaves] = useState(0);
	const [financialMonth, setFinancialMonth] = useState<number | null>(null);
	const [financialMonthLoading, setFinancialMonthLoading] =
		useState<boolean>(false);

	const [leaveRequestDetails, setleaveRequestDetails] = useState<any>({
		year: null,
		employeeId: null,
		FinancialYearRange: null,
		startDate: null,
		endDate: null,
		reason: null,
		leaveId: null,
	});

	const [formError, setFormError] = useState<any>({
		year: false,
		employeeId: false,
		startDate: false,
		FinancialYearRange: false,
		endDate: false,
		reason: false,
		leaveId: false,
	});

	const [file, setFile] = useState<any>(null);
	const [fileList, setFileList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [leaveDays, setLeaveDays] = useState<number>(0);
	const [leaveTypeDropdown, setLeaveTypeDropdown] = useState<any>([]);
	const [leaveLoading, setLeaveLoading] = useState(false);
	const [maxLeaves, setMaxLeaves] = useState(0);
	const [attachmentRequired, setAttachmentRequired] = useState(false);
	const [fetchedFile, setFetchedFile] = useState<any>(null);
	const [maxPaidLeavePerMonth, setMaxPaidLeavePerMonth] = useState(0);
	const [appliedLeaves, setAppliedLeaves] = useState<any>([]);

	const isDateOverlapping = (
		startDate: string,
		endDate: string,
		appliedLeaves: any
	) => {
		const start = dayjs(startDate).startOf('day');
		const end = dayjs(endDate).startOf('day');

		const overlappingLeaves = appliedLeaves.filter((leave: any) => {
			const leaveStartDate = dayjs(leave.startDate).startOf('day');
			const leaveEndDate = dayjs(leave.endDate).startOf('day');
			return (
				start.isSame(leaveStartDate) ||
				start.isSame(leaveEndDate) ||
				(start.isAfter(leaveStartDate) &&
					start.isBefore(leaveEndDate)) ||
				end.isSame(leaveStartDate) ||
				end.isSame(leaveEndDate) ||
				(end.isAfter(leaveStartDate) && end.isBefore(leaveEndDate)) ||
				(start.isBefore(leaveStartDate) && end.isAfter(leaveEndDate))
			);
		});

		const isHolidayOverlap = overlappingLeaves.length > 0;

		if (isHolidayOverlap) {
			const overlappingDates = overlappingLeaves
				.map(
					(leave: any) =>
						`${dayjs(leave.startDate).format(
							'DD-MM-YYYY'
						)} to ${dayjs(leave.endDate).format('DD-MM-YYYY')}`
				)
				.join(', ');

			toastText(
				`Leave dates overlap with existing leaves (${overlappingDates}). Please choose different dates.`,
				'error'
			);
			return true;
		}

		return false;
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...leaveRequestDetails },
			{ ...formError }
		);

		setFormError(checkFormError);

		const isOverlapping = isDateOverlapping(
			leaveRequestDetails.startDate,
			leaveRequestDetails.endDate,
			appliedLeaves
		);

		if (isOverlapping) {
			return;
		}

		if (hasFormError(checkFormError)) {
			return;
		} else {
			if (attachmentRequired && !file && !edit) {
				toastText('Attachment is required', 'error');
				return;
			}

			const leaveFormData = new FormData();
			leaveFormData.append('employeeId', leaveRequestDetails.employeeId);
			leaveFormData.append(
				'startDate',
				formatToDateOnly(leaveRequestDetails.startDate)
			);
			leaveFormData.append(
				'endDate',
				formatToDateOnly(leaveRequestDetails.endDate)
			);
			leaveFormData.append('reason', leaveRequestDetails.reason);
			leaveFormData.append('leaveId', leaveRequestDetails.leaveId);
			leaveFormData.append('noOfDays', leaveDays as any);
			leaveFormData.append('employeeMaxLeaves', employeeMaxLeaves as any);
			leaveFormData.append(
				'year',
				dayjs(leaveRequestDetails.year).format('YYYY')
			);
			leaveFormData.append('moduleName', 'LEAVE_REQUEST');
			if (edit) {
				if (
					fileList[0] &&
					(!fetchedFile || fileList[0].name !== fetchedFile.name)
				) {
					leaveFormData.append('file', fileList[0]);
				}
			} else {
				leaveFormData.append('file', file);
			}

			setIsLoading(true);
			try {
				let response;
				if (edit) {
					response = await leaveRequestApi.updateLeaveRequest(
						edit.id,
						leaveFormData
					);
				} else {
					response = await leaveRequestApi.createLeaveRequest(
						leaveFormData
					);
				}

				fetchLeaveRequest();
				let message = edit
					? 'Leave Request has been updated successfully'
					: 'Leave Request has been requested successfully';

				toastText(message, 'success');
				closeModal();
			} catch (err: any) {
				let message;
				if (err.response?.data?.error?.code === 103) {
					message = err.response.data.message;
				} else {
					message = `Something went wrong in ${
						edit ? 'Updating' : 'Creating'
					} Leave Request`;
				}

				toastText(message, 'error');
			}
			setIsLoading(false);
		}
	};

	const closeModal = () => {
		setleaveRequestDetails({
			year: null,
			employeeId: null,
			startDate: null,
			endDate: null,
			reason: null,
			leaveId: null,
		});
		setEmployeeMaxLeaves(0);
		setLeaveTypeDropdown([]);
		setFormError({
			year: false,
			employeeId: false,
			startDate: false,
			endDate: false,
			reason: false,
			leaveId: false,
		});
		setEdit(null);
		setFile(null);
		setLeaveDays(0);
		setFileList([]);
		setMaxPaidLeavePerMonth(0);
		setMaxLeaves(0);
		setAttachmentRequired(false);
		handleCancel();
	};

	const fetchLeaveType = async (employeeId: string, year: string) => {
		try {
			setLeaveLoading(true);
			const response = await leaveManagementApi.getLeaveTypeByEmployeeId(
				employeeId,
				year
			);

			setLeaveTypeDropdown(response?.data?.data.data);
			let _appliedLeaves = response?.data?.data.appliedLeaves;
			if (edit) {
				_appliedLeaves = _appliedLeaves.filter(
					(leave: any) => leave.id !== edit.id
				);
			}
			setAppliedLeaves(_appliedLeaves);
			setMaxPaidLeavePerMonth(response?.data?.data.maxPaidLeavePerMonth);
		} catch (err: any) {
		} finally {
			setLeaveLoading(false);
		}
	};

	const countNoOfDays = (startDate: any, endDate: any) => {
		if (startDate && endDate) {
			const _startDate = new Date(startDate);
			const _endDate = new Date(endDate);
			_startDate.setHours(0, 0, 0, 0);
			_endDate.setHours(0, 0, 0, 0);

			const diffDays = Math.round(
				Math.abs(_startDate.getTime() - _endDate.getTime()) /
					(1000 * 60 * 60 * 24) +
					1
			);
			setLeaveDays(diffDays);
			return diffDays;
		} else {
			setLeaveDays(0);
			return 0;
		}
	};

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		if (name === 'year') {
			const fiscalMonth = financialMonth;
			const startDateOfFiscalMonth = dayjs()
				.year(dayjs(value).year())
				.month(fiscalMonth ?? 0)
				.startOf('month');

			const _FinancialYearRange = getFinancialYearRange(
				dayjs(value).year(),
				fiscalMonth ?? 0
			);

			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					year: dayjs(value).format('YYYY'),
					startDate: startDateOfFiscalMonth,
					endDate: '',
					FinancialYearRange: _FinancialYearRange,
					leaveId: '',
				};
				return newDetails;
			});
			if (leaveRequestDetails.employeeId) {
				fetchLeaveType(
					leaveRequestDetails.employeeId,
					dayjs(value).format('YYYY')
				);
			}
		}
		if (name === 'employeeId') {
			setLeaveTypeDropdown([]);
			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					leaveId: '',
					startDate: '',
					endDate: '',
				};
				return newDetails;
			});

			fetchLeaveType(
				value as string,
				dayjs(leaveRequestDetails.year).format('YYYY')
			);
		}

		if (name === 'startDate') {
			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					endDate: null,
				};
				return newDetails;
			});
		}

		if (name === 'leaveId') {
			const leaveType = leaveTypeDropdown.find(
				(leave: any) => leave.value === value
			);
			setMaxLeaves(leaveType?.totalLeaves);
			setEmployeeMaxLeaves(leaveType?.maxLeaves || 0);
			setMaxPaidLeavePerMonth(leaveType?.maxPaidLeavePerMonth || 0);
			setAttachmentRequired(leaveType?.attachmentRequired || false);
			setLeaveDays(0);
			setleaveRequestDetails((prev: any) => {
				const newDetails = {
					...prev,
					startDate: '',
					endDate: '',
				};
				return newDetails;
			});
		}
		OnChange(value, name);
	};

	const OnChange = (value: string | number | null, key: string) => {
		setleaveRequestDetails((prev: any) => {
			const newDetails = {
				...prev,
				[key]: value,
			};
			if (key === 'startDate' || key === 'endDate') {
				countNoOfDays(newDetails.startDate, newDetails.endDate);
			}
			return newDetails;
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};
	let isRemoving = false;

	const propsUpload = {
		name: 'file',
		maxCount: 1,
		fileList: fileList,
		beforeUpload: (file: UploadFile) => {
			if (!file) {
				return;
			}

			const isLt1M = file.size! / 1024 / 1024 < 5;
			if (!isLt1M) {
				toastText('File must be smaller than 5MB!', 'error');
				return Upload.LIST_IGNORE;
			}

			setFile(file);
			setFileList([file]);
			return false;
		},
		onChange(info: UploadChangeParam<UploadFile>) {
			const { file } = info;
			if (!isRemoving) {
				setFile(file);
				setFileList([file]);
			} else {
				isRemoving = false;
				setFile(null);
				setFileList([]);
			}
		},
		onDrop: () => {
			setFile(null);
			setFileList([]);
		},
		onRemove: () => {
			isRemoving = true;
		},
	};
	const CustomDragger = (
		<Dragger {...propsUpload} className="test">
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-hint">
				Drag and drop a file or choose file from Device.
				<br />{' '}
				<span className="color-purple">Maximum file size: 5MB</span>
			</p>
		</Dragger>
	);

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '60px',
			minHeight: '42px',
			disabled: isLoading,
			isSubmit: false,
			onclick: () => {
				closeModal();
			},
		},
		{
			text: edit ? 'Edit' : 'Save',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '100px',
			minHeight: '42px',
			isSubmit: true,
			disabled: leaveLoading,
			onclick: () => {
				handleSubmit();
			},
		},
		
	];

	const fetchFinancialYear = async () => {
		try {
			setFinancialMonthLoading(true);
			const financialYear = await companySetup.getFinancialYear();
			const _month = financialYear.data.data;
			setFinancialMonth(_month - 1);
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		} finally {
			setFinancialMonthLoading(false);
		}
	};

	useEffect(() => {
		fetchFinancialYear();
	}, []);

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, [dispatch]);

	useEffect(() => {
		if (edit) {
			const dateRange = getFinancialYearRange(
				edit.year,
				financialMonth ?? 0
			);
			const editData = {
				startDate: dayjs(edit.startDate),
				endDate: dayjs(edit.endDate),
				reason: edit.reason,
				status: edit.status,
				employeeId: edit?.employeeId,
				leaveId: edit.leaveId,
				FinancialYearRange: dateRange,
				noOfDays: edit.noOfDays,
				year: dayjs().year(edit.year),
			};

			if (edit.documentName) {
				const file = {
					name: edit.documentName,
				};
				setFileList([file]);
				setFetchedFile(file);
			} else {
				setFileList([]);
				setFetchedFile(null);
			}

			setleaveRequestDetails(editData);
			countNoOfDays(editData.startDate, editData.endDate);
			fetchLeaveType(edit.employeeId, edit.year);
		}
	}, [edit]);

	useEffect(() => {
		if (edit && leaveTypeDropdown.length > 0) {
			const _maxLeaves = leaveTypeDropdown.find(
				(leave: any) => leave.value === edit.leaveId
			);
			setMaxLeaves(_maxLeaves?.totalLeaves);
			setEmployeeMaxLeaves(_maxLeaves?.maxLeaves);
			setMaxPaidLeavePerMonth(_maxLeaves?.maxPaidLeavePerMonth);
			setAttachmentRequired(_maxLeaves?.attachmentRequired);
		}
	}, [leaveTypeDropdown, edit]);

	return (
		<Modal
			open={isAddLeaveRequestModalOpen}
			onOk={handleSubmit}
			onCancel={closeModal}
			okText={'Save'}
			closable={false}
			width={800}
			footer={null}
			title={<h2>{edit ? 'Edit Leave Request' : 'Add Leave Request'}</h2>}
			className="addLeaveRequestModal"
		>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<Row className={styles['form-container-card']} gutter={50}>
				<Col span={12} className={`${styles['col']}`}>
					<DatePickerField
						onChange={(value) => {
							handleChange(value, 'year', true);
						}}
						value={leaveRequestDetails.year}
						isError={formError.year}
						disabledBeforeDates={dayjs().startOf('day')}
						placeholder="Select a year"
						label="Select a year"
						required
						name="year"
						helperText="Select the year"
						picker="year"
						disabled={financialMonthLoading}
					/>
				</Col>
				<Col span={12} className={`${styles['col']}`}>
					<InputField
						name="financialYear"
						value={
							leaveRequestDetails.year
								? leaveRequestDetails.FinancialYearRange
								: 'Loading...'
						}
						label="Financial Year"
						required={false}
						helperText="Pay period name required"
						onChange={(value) => {}}
						placeholder="Select a financial year"
						isError={false}
						disabled={true}
					/>
				</Col>

				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<SelectDropdown
						placeholder="Employee Name"
						options={
							constantDropdownOptions?.employees
								? constantDropdownOptions?.employees
								: []
						}
						value={leaveRequestDetails?.employeeId}
						onChange={(value: any) =>
							handleChange(value, 'employeeId', true)
						}
						size="large"
						required={true}
						helperText="Employee Name required"
						label="Employee Code | Employee Name"
						isError={formError.employeeId}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<SelectDropdown
						placeholder="Leave Type"
						options={leaveTypeDropdown ? leaveTypeDropdown : []}
						value={leaveRequestDetails?.leaveId}
						disabled={leaveLoading}
						onChange={(value: any) =>
							handleChange(value, 'leaveId', true)
						}
						size="large"
						required={true}
						helperText="Leave Type required"
						label="Leave Type"
						isError={formError.leaveId}
						loading={leaveLoading}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="startDate"
						onChange={(value) => {
							handleChange(value, 'startDate', true);
						}}
						value={leaveRequestDetails.startDate}
						isError={formError.startDate}
						disabled={leaveLoading}
						required={true}
						helperText=" Start Date required"
						label="Start Date"
						disabledBeforeDates={dayjs(
							`${dayjs(leaveRequestDetails.year).year()}-${
								financialMonth ? financialMonth + 1 : 1
							}`
						).startOf('day')}
						disabledAfterDates={dayjs(
							`${dayjs(leaveRequestDetails.year).year() + 1}-${
								financialMonth ? financialMonth : 1
							}`
						).endOf('month')}
						placeholder="Select Start Date"
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="endDate"
						onChange={(value) => {
							handleChange(value, 'endDate', true);
						}}
						value={leaveRequestDetails.endDate}
						isError={formError.endDate}
						required={true}
						label="End Date"
						helperText="End Date required"
						placeholder="Select End Date"
						disabled={
							(leaveRequestDetails.startDate ? false : true) ||
							leaveLoading
						}
						disabledBeforeDates={leaveRequestDetails.startDate}
						disabledAfterDates={
							maxLeaves !== -1 &&
							dayjs(leaveRequestDetails.startDate).add(
								(maxPaidLeavePerMonth <= maxLeaves
									? maxPaidLeavePerMonth
									: maxLeaves) - 1,
								'day'
							)
						}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="noOfDays"
						value={leaveDays}
						label="Number of Days"
						required={false}
						helperText="Number of days"
						onChange={(value) => {}}
						isError={false}
						disabled={true}
					/>
				</Col>

				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputField
						name="maxLeaves"
						value={
							maxLeaves - leaveDays >= 0
								? maxLeaves - leaveDays
								: 0
						}
						label="Remaining Leaves"
						required={false}
						helperText="Total Leaves"
						onChange={(value) => {}}
						isError={false}
						disabled={true}
					/>
				</Col>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<TextareaFieldInput
						name="reason"
						value={leaveRequestDetails.reason}
						label="Reason"
						required={true}
						helperText="Reason required"
						onChange={(value) =>
							handleChange(value, 'reason', true)
						}
						isError={formError.reason}
					/>
				</Col>
			</Row>
			{attachmentRequired && (
				<div className={styles['modalUploadContainer']}>
					<label className={styles['modalUploadLabel']}>
						{'Attachment'}
						{!edit && attachmentRequired && (
							<span className={styles['requiredAsterisk']}>
								*
							</span>
						)}
					</label>
					<Form.Item required>{CustomDragger}</Form.Item>
				</div>
			)}

			<div className={styles['buttons']}>
				<Buttons buttons={myButtons} />
			</div>
		</Modal>
	);
};

export default AddLeaveRequestModal;
