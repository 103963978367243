import { employeeApi } from 'Api/employee';
import Buttons from 'components/Global/Buttons';
import GlobalPayPeriod from 'components/Global/PayPeriod';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastText } from 'utils/utils';
import EarningsModal from './EarningsBody';
import styles from './index.module.scss';

const EarningsTab = (props: any) => {
	const {
		year,
		month,
		employeeId,
		onYearChange,
		onMonthChange,
		activeTab,
		isUSD,
	} = props;

	const [earningsAmount, setEarningsAmount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedPayPeriod, setSelectedPayPeriod] = useState('');

	const { data: payPeriodData } = useSelector(
		(state: any) => state.payPeriod
	);

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			disabled: isLoading,
				minWidth: '60px',
			minHeight: '42px',
			isSubmit: false,
			font: '16px',
			onclick: () => {
				// handleCancel();
			},
		},
		{
			text: 'Save',
			isLoading: isLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			disabled: isLoading,
			minWidth: '100px',
			minHeight: '42px',
			isSubmit: true,
			onclick: () => {
				handleSave();
			},
		},
	];

	const [data, setData] = useState<any>();
	const [isFetchDataLoading, setIsFetchDataLoading] =
		useState<boolean>(false);

	const handleDataChange = (data: any) => {
		setData(data);
		const total = data?.reduce(
			(sum: any, item: any) =>
				item.isChecked ? sum + parseFloat(item?.amount || '0.00') : sum,
			0
		);
		setEarningsAmount(total);
	};

	const handleFetchLoading = (status: boolean) => {
		setIsFetchDataLoading(status);
	};

	const handleSave = async () => {
		const finalData = {
			payPeriod: selectedPayPeriod,
			employeeId,
			data,
		};

		try {
			setIsLoading(true);
			const apiCall = await employeeApi.createEmployeeEarnings(finalData);

			let message =
				apiCall?.data?.message || `Earnings saved successfully`;
			toastText(message, 'success');
		} catch (error: any) {
			let message =
				error?.data?.message ||
				`something went wrong failed to save earnings`;
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (payPeriodData?.length > 1) {
			setSelectedPayPeriod(payPeriodData[1].value);
		}
	}, [year]);

	return (
		<div>
			<div className={styles.earningContainer}>
				<div className={styles.headerBox}>
					<div className={styles.headerContent}>
						<div className={styles.titleContainer}>
							<div className={styles.titleText}>{'Earnings'}</div>
							<div className={styles.separation}></div>
							<div className={styles.earningsCount}>
								<div className={styles.titleEarnings}>
									Total Earnings
								</div>
								<div className={styles.earningsAmount}>
									{isUSD ? 'USD' : 'ZMW'}
									{!isFetchDataLoading &&
										earningsAmount?.toFixed(2)}
								</div>
							</div>
						</div>
						<div className={styles.yearMonthContent}>
							{/* <YearMonthPicker
							onYearChange={onYearChange}
							onMonthChange={onMonthChange}
							year={dayjs().year(year)}
							month={month}
						/> */}
							<GlobalPayPeriod
								selectedYear={year}
								handleSelectedYear={onYearChange}
								selectedPayPeriod={selectedPayPeriod}
								handlePayPeriod={(value) =>
									setSelectedPayPeriod(value)
								}
								isRow={true}
							/>
						</div>
					</div>
				</div>
				<EarningsModal
					onDataChange={(data) => handleDataChange(data)}
					onLoadingChange={(loading) => handleFetchLoading(loading)}
					month={month}
					year={year}
					employeeId={employeeId}
					activeTab={activeTab}
					selectedPayPeriod={selectedPayPeriod}
					isUSD={isUSD}
				/>
				<div className={styles.buttons}>
					{!isFetchDataLoading && <Buttons buttons={myButtons} />}
				</div>
			</div>
		</div>
	);
};

export default EarningsTab;
