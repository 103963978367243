import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import DateRangePickerField from 'components/Global/DateRangePicker';
import SearchComponent from 'components/Global/SearchComponent';
import { payrollStatusOptions } from 'constants/payrollStatus';
import { useContext } from 'react';
import styles from './index.module.scss';

type Props = {
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	button: any;
	monthValue: any;
	yearValue: any;
	handleMonthDatePicker: any;
	handleYearDatePicker: any;
	StatusFilter: any;
	handleStatusFilter: (value: string) => void;
	handleDatePicker: any;
	dateValue: any;
};

const LeaveRequestHeader = (props: Props) => {
	const {
		searchText,
		handleSearch,
		handleSearchApi,
		button,
		StatusFilter,
		handleStatusFilter,
		handleDatePicker,
		dateValue,
	} = props;
	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Leave_Request');

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search Name | Code | Reason"
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<Space>
					<DateRangePickerField
						className={styles['employee-header-item']}
						name={'dateFilter'}
						value={dateValue}
						required={false}
						picker="month"
						isError={false}
						onChange={handleDatePicker}
					/>
				</Space>

				<Select
					style={{ width: 200 }}
					className={styles['employee-header-item']}
					value={StatusFilter}
					options={payrollStatusOptions}
					onChange={(value) => handleStatusFilter(value)}
					size="large"
					placeholder="Select Status"
				/>
			</Space>
			{isAdd && <Buttons buttons={button} />}
		</div>
	);
};

export default LeaveRequestHeader;
