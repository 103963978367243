import { Button, Modal, Spin, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import './index.scss';
import styles from './index.module.scss';
import { CompanyDetails } from './CompanyDetails';
import { CurrencyDetails } from './CurrencyDetails';
import { BankDetails } from './BankDetails';

type Props = {
	isOpen: boolean;
	onCancel: () => void;
	edit: any;
};

const EditCompanyModal = (props: Props) => {
	const { onCancel, isOpen, edit } = props;
	const [activeKey, setActiveKey] = React.useState('companyDetails');
	const changeTab = (key: any) => {
		setActiveKey(key);
	};

	let tabItems = [
		{
			key: 'companyDetails',
			label: 'Company details',
			children: (
				<CompanyDetails
					changeTab={changeTab}
					onCancel={onCancel}
					editData={edit}
				/>
			),
		},
		{
			key: 'bankDetails',
			label: 'Bank details',
			children: <BankDetails changeTab={changeTab} onCancel={onCancel} />,
		},
		{
			key: 'currency',
			label: 'Currency Setup',
			children: <CurrencyDetails onCancel={onCancel} />,
		},
	];

	return (
		<Modal
			open={isOpen}
			onCancel={onCancel}
			footer={null}
			width={1500}
			closable={false}
		>
			<div className={styles['modal-header']}>
				<Typography.Title level={4}>
					<span>Add Company Details</span>
				</Typography.Title>
				<Button
					//className={styles['close-button']}
					icon={<CloseOutlined />}
					onClick={onCancel}
				/>
			</div>
			<div className={styles['company-component']}>
				<div className="company-component-tabs">
					<Tabs
						defaultActiveKey={activeKey}
						activeKey={activeKey}
						items={tabItems}
						onChange={(key) => changeTab(key)}
						style={{
							fontSize: '18px',
							marginBottom: '40px',
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default EditCompanyModal;
