import { Table, Tooltip } from 'antd';
import styles from './index.module.scss';
import Ellipse from 'components/Global/Ellipse';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';
import dayjs from 'dayjs';

const { Column } = Table;

type Props = {
	ReportData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const JournalEntryReportTable = (props: Props) => {
	const {
		ReportData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={ReportData}
				scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
			>
				<Column
					title="Pay Period Name"
					dataIndex="payPeriod"
					key="payPeriod"
					className="bg-white"
					render={(text) => text?.name}
				/>
				<Column
					title="Generated Date"
					dataIndex="createdAt"
					key="createdAt"
					sorter={true}
					className="bg-white"
					render={(text) => dayjs(text).format('DD/MM/YYYY')}
				/>
				<Column
					title="Generated By"
					dataIndex="createdBy"
					key="createdBy"
					className="bg-white"
					render={(text) => text?.fullName}
				/>
				<Column
					title="Report"
					dataIndex="documentLink"
					key="documentLink"
					className="bg-white"
					render={(text, record: any) => (
						<div className="flex gap-2">
							{record.pdfDocumentLink ? (
								<Tooltip title={record.pdfDocName}>
									<a
										href={record.pdfDocumentLink}
										target="_blank"
										rel="noopener noreferrer"
										style={{
											cursor: 'pointer',
											opacity: 1,
										}}
									>
										<div className="flex items-center gap-1">
											<FaFilePdf
												color="#d32f2f"
												size={20}
											/>
										</div>
									</a>
								</Tooltip>
							) : null}
							{record.csvDocumentLink ? (
								<Tooltip title={record.csvDocName}>
									<a
										href={record.csvDocumentLink}
										target="_blank"
										rel="noopener noreferrer"
										style={{
											cursor: 'pointer',
											opacity: 1,
										}}
									>
										<div className="flex items-center gap-1">
											<FaFileExcel
												color="#217346"
												size={20}
											/>
										</div>
									</a>
								</Tooltip>
							) : null}
							{!record.pdfDocumentLink &&
								!record.csvDocumentLink &&
								'-'}
						</div>
					)}
				/>
			</Table>
		</div>
	);
};

export default JournalEntryReportTable;
