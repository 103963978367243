import { Button, Checkbox, Table, Typography } from 'antd';
// import { permissionObject } from 'constants/Data';
import { FC, useEffect, useState } from 'react';
// import { getPermissionObject, toastText } from 'utils/utils';
import { putApi } from 'apis';
import { getPermissionObject, permissionObject, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { PermissionProps, SideDrawerBodyProps } from './types';
// import { userCheck } from "redux/slice/userCheckSlice";

const { Text } = Typography;

const PermissionBody: FC<SideDrawerBodyProps> = (props) => {
	// Inits
	const { Column } = Table;
	const { permissions, closeDrawerByAnimation, selectedRole, isLoading } =
		props;
	const [loading, setLoading] = useState<boolean>(false);

	const [permissionsCopy, setPermissionsCopy] = useState<any>([]);

	useEffect(() => {
		// Create a copy of the permission data for modification
		const formattedPermissionData = getPermissionObject(
			permissionObject,
			permissions
		);
		setPermissionsCopy(formattedPermissionData);
	}, [permissions]);

	// Handle changes in individual permissions
	const PermissionChangeHandler = (
		data: any,
		e: any,
		permissionName: string
	) => {
		const PermissionUpdate = permissionsCopy?.map((item: any) => {
			let duplicateItem = { ...item };
			if (item.id === data.id) {
				if (e.target.checked) {
					if (permissionName === 'all') {
						duplicateItem = {
							...duplicateItem,
							all: true,
							view: true,
							edit: true,
							delete: true,
							add: true,
							approval: true,
						};
					} else if (permissionName === 'add') {
						duplicateItem = {
							...duplicateItem,
							view: true,
							add: true,
						};
					} else if (permissionName === 'edit') {
						duplicateItem = {
							...duplicateItem,
							view: true,
							edit: true,
						};
					} else if (permissionName === 'delete') {
						duplicateItem = {
							...duplicateItem,
							view: true,
							delete: true,
						};
					} else if (permissionName === 'approval') {
						duplicateItem = {
							...duplicateItem,
							approval: true,
							view: true,
						};
					} else {
						duplicateItem = {
							...duplicateItem,
							view: true,
						};
					}
					duplicateItem[permissionName] = true;
					const propertyNames = Object.keys(duplicateItem).filter(
						(key) =>
							key !== 'moduleName' &&
							key !== 'all' &&
							key !== 'isBold'
					);
					const anyFalseValue = propertyNames.some(
						(key) => duplicateItem[key] === false
					);
					duplicateItem.all = !anyFalseValue;
					return duplicateItem;
				} else {
					if (permissionName === 'all' || permissionName === 'view') {
						return {
							...item,
							all: false,
							view: false,
							edit: false,
							delete: false,
							approval: false,
							add: false,
						};
					} else {
						return { ...item, [permissionName]: false, all: false };
					}
				}
			}
			return item;
		});
		setPermissionsCopy(PermissionUpdate);
	};

	// Handle saving permissions
	const savePermissionHandler = async () => {
		try {
			setLoading(true);
			// removing the header permission
			const actualPermissions = permissionsCopy.filter(
				(singlePermission: any) => singlePermission.id
			);

			const data = {
				roleId: selectedRole.id,
				permissions: actualPermissions,
			};

			await putApi('/permission', data);

			toastText('Permissions updated successfully', 'success');

			closeDrawerByAnimation();
		} catch (error) {
			toastText('Something went wrong in update permission', 'error');
		} finally {
			setLoading(false);
		}
	};

	// JSX
	return (
		<div className={'permission-details-drawer'}>
			<Table
				// columns={permissionColumn}
				dataSource={permissionsCopy} //permissionsCopy
				pagination={false}
				className={`${styles['dynamic-permission-table']} table-global`}
				rowClassName={(columnData: any) => {
					if (columnData.isBold) {
						return 'table-weight-700';
					} else {
						return '';
					}
				}}
				scroll={{ y: 'calc(100vh - 220px)' }}
				loading={isLoading}
			>
				<Column
					title="Module Name"
					dataIndex="moduleName"
					key="moduleName"
					width={'40%'}
					render={(value: string, rowData: PermissionProps) => {
						return (
							<Text
								className={
									rowData?.isParentModule == true
										? 'parent-module'
										: 'child-module'
								}
							>
								{value}
							</Text>
						);
					}}
				/>
				<Column
					title="All"
					dataIndex="all"
					key="all"
					width={'10%'}
					render={(value: boolean, rowData: PermissionProps) => {
						return (
							<>
								{rowData?.sortId ? (
									<Checkbox
										checked={value}
										defaultChecked={value}
										onChange={(e) =>
											PermissionChangeHandler(
												rowData,
												e,
												'all'
											)
										}
									></Checkbox>
								) : (
									` `
								)}
							</>
						);
					}}
				/>
				<Column
					title="Add"
					dataIndex="add"
					key="add"
					width={'10%'}
					render={(value: boolean, rowData: PermissionProps) => {
						return (
							<>
								{rowData?.sortId ? (
									<Checkbox
										checked={
											rowData.isAddDisabled
												? false
												: value
										}
										defaultChecked={value}
										disabled={rowData.isAddDisabled}
										onChange={(e) =>
											PermissionChangeHandler(
												rowData,
												e,
												'add'
											)
										}
									></Checkbox>
								) : (
									` `
								)}
							</>
						);
					}}
				/>
				<Column
					title="View"
					dataIndex="view"
					key="view"
					width={'10%'}
					render={(value: boolean, rowData: PermissionProps) => (
						<>
							{rowData?.sortId ? (
								<Checkbox
									checked={
										rowData.isViewDisabled ? false : value
									}
									defaultChecked={value}
									disabled={rowData.isViewDisabled}
									onChange={(e) =>
										PermissionChangeHandler(
											rowData,
											e,
											'view'
										)
									}
								></Checkbox>
							) : (
								` `
							)}
						</>
					)}
				/>
				<Column
					title="Edit"
					dataIndex="edit"
					key="edit"
					width={'10%'}
					render={(value: boolean, rowData: PermissionProps) => (
						<>
							{rowData?.sortId ? (
								<Checkbox
									checked={
										rowData.isEditDisabled ? false : value
									}
									defaultChecked={value}
									disabled={rowData.isEditDisabled}
									onChange={(e) =>
										PermissionChangeHandler(
											rowData,
											e,
											'edit'
										)
									}
								></Checkbox>
							) : (
								` `
							)}
						</>
					)}
				/>
				<Column
					title="Delete"
					dataIndex="delete"
					key="delete"
					width={'12%'}
					render={(value: boolean, rowData: PermissionProps) => {
						return (
							<>
								{rowData?.sortId ? (
									<Checkbox
										checked={
											rowData.isDeleteDisabled
												? false
												: value
										}
										defaultChecked={value}
										disabled={rowData.isDeleteDisabled}
										onChange={(e) =>
											PermissionChangeHandler(
												rowData,
												e,
												'delete'
											)
										}
									></Checkbox>
								) : (
									` `
								)}
							</>
						);
					}}
				/>
				<Column
					title="Approval"
					dataIndex="approval"
					key="approval"
					width={'14%'}
					render={(value: boolean, rowData: PermissionProps) => {
						return (
							<>
								{rowData?.sortId ? (
									<Checkbox
										checked={
											rowData.isApprovalDisabled
												? false
												: value
										}
										defaultChecked={value}
										disabled={rowData.isApprovalDisabled}
										onChange={(e) =>
											PermissionChangeHandler(
												rowData,
												e,
												'approval'
											)
										}
									></Checkbox>
								) : (
									` `
								)}
							</>
						);
					}}
				/>
			</Table>
			<div className={styles['side-drawer-form']}>
				<div className={styles['side-drawer-form__buttons']}>
					<Button
						onClick={savePermissionHandler}
						loading={loading}
						className={`${styles['side-drawer-form__buttons--save']} ${styles['side-drawer-form__buttons--btn']}`}
					>
						Save
					</Button>
					<button
						className={`${styles['side-drawer-form__buttons--cancel']} ${styles['side-drawer-form__buttons--btn']}`}
						onClick={closeDrawerByAnimation}
						disabled={loading}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default PermissionBody;
