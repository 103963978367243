import { useEffect, useState } from 'react';
import { CashSvg, ChequeSvg, RightArrowSvg } from 'utils/svgs';
import Cheque from './Cheque';
import DirectDeposit from './DirectDeposit';

import styles from './index.module.scss';
import CustomCollapsePanel from 'components/Global/Collpase';
import { decrypt, toastText } from 'utils/utils';
import { directDepositApi } from 'Api/directDeposit';
import { useSearchParams } from 'react-router-dom';

type Props = {
	isUsd: boolean;
};
const PaymentInfo = (props: Props) => {
	const { isUsd } = props;
	const [activeKey, setActiveKey] = useState<string | null>(null);
	const [isVerifiedAccount, setIsVerifiedAccount] = useState(false);
	const [searchParams] = useSearchParams();
	const [paymentDetails, setPaymentDetails] = useState<any>({
		accountHolderName: null,
		bankId: null,
		accountNumber: null,
		sortCode: null,
		isVerified: false,
		beneficiaryAddr1: null,
		beneficiaryAddr2: null,
		beneficiaryAddr3: null,
		beneficiaryBankCode: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const handleCollapseClose = () => {
		setActiveKey(null); // Close all panels
	};
	const trueVerifiedAccount = () => {
		setIsVerifiedAccount(true);
	};

	const falseVerifiedAccount = () => {
		setIsVerifiedAccount(false);
	};

	useEffect(() => {
		const fetchDirectDepositDetails = async () => {
			setIsLoading(true);
			const employeeId = searchParams.get('employeeId');

			if (!employeeId) {
				toastText('Employee ID is missing', 'error');
				setIsLoading(false);
				return;
			}
			try {
				const response =
					await directDepositApi.fetchDirectDepositByEmployeeId(
						employeeId
					);
				const data = response.data;

				let decryptedAccountNumber;
				try {
					decryptedAccountNumber = decrypt(data.accountNumber);
				} catch (error) {
					console.error('Error decrypting account number:', error);
					decryptedAccountNumber = data.accountNumber || '';
				}
				setPaymentDetails({
					accountHolderName: data.accountHolderName,
					bankId: data.bankDetailsId,
					accountNumber: decryptedAccountNumber,
					sortCode: data.sortCode,
					isVerified: data.isVerified,
					beneficiaryAddr1: data?.beneficiaryAddr1,
					beneficiaryAddr2: data?.beneficiaryAddr2,
					beneficiaryAddr3: data?.beneficiaryAddr3,
					beneficiaryBankCode: data.beneficiaryBankCode,
				});
				data.isVerified
					? trueVerifiedAccount()
					: falseVerifiedAccount();
			} catch (err) {
				toastText('Failed to load direct deposit details', 'error');
			} finally {
				setIsLoading(false);
			}
		};

		fetchDirectDepositDetails();
	}, [searchParams]);

	return (
		<div className={styles['form']}>
			<div className={styles['form-container']}>
				<div className={styles['form-container-head']}>
					<p className={styles['form-container-head-title']}>
						Payment Info
					</p>
				</div>

				<div className={styles.collapse}>
					<div className={styles['collapse-menu']}>
						<p className={styles['collapse-title']}>
							How would you like to pay this employee?{' '}
							<sup className={styles['collapse-required']}>*</sup>
						</p>

						<CustomCollapsePanel
							header={
								isUsd
									? 'Direct Deposit'
									: isLoading
									? 'Direct Deposit(Loading...)'
									: isVerifiedAccount
									? 'Direct Deposit (Verified)'
									: 'Direct Deposit (Not Verified)'
							}
							icon={<RightArrowSvg />}
							activeKey={activeKey}
							setActiveKey={setActiveKey}
							panelKey="0"
							isOpen={false}
						>
							<DirectDeposit
								onCancel={handleCollapseClose}
								falseVerifiedAccount={falseVerifiedAccount}
								trueVerifiedAccount={trueVerifiedAccount}
								isDetailsLoading={isLoading}
								paymentDetails={paymentDetails}
								isUsd={isUsd}
							/>
						</CustomCollapsePanel>

						<CustomCollapsePanel
							header="Cheque"
							icon={<ChequeSvg />}
							activeKey={activeKey}
							setActiveKey={setActiveKey}
							panelKey="1"
							isOpen={false}
						>
							<Cheque onCancel={handleCollapseClose} />
						</CustomCollapsePanel>

						<CustomCollapsePanel
							header="Cash"
							icon={<CashSvg />}
							activeKey={activeKey}
							setActiveKey={setActiveKey}
							panelKey="2"
							isOpen={false}
						>
							<div>Cash</div>
						</CustomCollapsePanel>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentInfo;
