import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import AddHolidayModal from './AddHolidayModal';
import HolidayHeader from './Header';
import HolidayTable from './HolidayTable';
import styles from './index.module.scss';
import AddHolidayManuallyModal from './AddHolidayManuallyModal';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { holidayApi } from 'Api/masters/holiday';

const HolidayComponent = () => {
	const navigate = useNavigate();
	const [holidayData, setHolidayData] = useState<any[]>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deleteHolidayId, setDeleteHolidayId] = useState<string | null>(null);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isAddHolidayModalOpen, setIsAddHolidayModalOpen] = useState(false);
	const [isAddHolidayManuallyModalOpen, setIsAddHolidayaManuallModalOpen] =
		useState(false);
	const [edit, setEdit] = useState<any>(null);
	const [searchText, setSearchText] = useState('');
	const [date, setDate] = useState<any>(dayjs());

	const handleDatePicker = (value: any) => {
		setDate(value);
	};
	const handleSearch = (value: string) => {
		setSearchText(value);
	};
	const handleSearchApi = async (value: string) => {
		//search api
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchHoliday = async () => {
		try {
			const query = {
				page: currentPage,
				searchText: searchText,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				year: dayjs(date).format('YYYY'),
			};
			setIsLoading(true);
			const holidayData = await holidayApi.getAllHolidays(query);

			setHolidayData(holidayData.data.data);
			setTotalRecords(holidayData.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching holiday.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const deleteHandler = async () => {
		if (deleteHolidayId) {
			try {
				setIsDeleteLoading(true);
				await holidayApi.deleteHoliday(deleteHolidayId);
				toastText('Holiday deleted successfully', 'success');
				cancelDeleteModal();
				fetchHoliday();
			} catch (error: any) {
				toastText(error.response.data.message, 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const cancelDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setDeleteHolidayId(null);
	};

	const showDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const handleCancel = () => {
		setIsAddHolidayModalOpen(false);
	};

	const showAddHolidayModal = () => {
		setIsAddHolidayModalOpen(true);
	};

	const showAddHolidayManuallyModal = () => {
		setIsAddHolidayaManuallModalOpen(true);
	};

	const closeAddHolidayManuallyModal = () => {
		setIsAddHolidayaManuallModalOpen(false);
		setEdit(null);
	};

	const buttons = [
		{
			text: 'Add Holiday',
			isLoading: false,
			className: 'btn-blue',
			icon: <AddSvg />,
			isSubmit: true,
			onclick: showAddHolidayModal,
			disabled: isLoading,
		},
	];

	useEffect(() => {
		fetchHoliday();
	}, [currentPage, date, sortField, sortOrder, pageSize, searchText]);
	return (
		<div>
			<div className={styles['employee-container']}>
				<HolidayHeader
					handleDatePicker={handleDatePicker}
					dateValue={date}
					button={buttons}
					searchText={searchText}
					handleSearch={handleSearch}
					handleSearchApi={handleSearchApi}
				/>
				<HolidayTable
					holidayData={holidayData}
					totalRecords={totalRecords}
					currentPage={currentPage}
					pageSize={pageSize}
					isLoading={isLoading}
					setEditData={setEdit}
					tableChangeHandler={tableChangeHandler}
					fetchRecords={fetchHoliday}
					showAddHolidayManuallyModal={showAddHolidayManuallyModal}
					showDeleteModal={showDeleteModal}
					setDeleteHolidayId={setDeleteHolidayId}
				/>

				<AddHolidayModal
					isAddHolidayModalOpen={isAddHolidayModalOpen}
					handleCancel={handleCancel}
					fetchHolidays={fetchHoliday}
					showAddHolidayManuallyModal={showAddHolidayManuallyModal}
				/>

				<AddHolidayManuallyModal
					isAddHolidayManuallyModalOpen={
						isAddHolidayManuallyModalOpen
					}
					handleCancel={closeAddHolidayManuallyModal}
					edit={edit}
					fetchHoliday={fetchHoliday}
				/>

				<ConfirmDelete
					isModalOpen={isDeleteModalOpen}
					handleCancel={cancelDeleteModal}
					deleteHandler={deleteHandler}
					isLoading={isDeleteLoading}
					handleOk={cancelDeleteModal}
				/>
			</div>
		</div>
	);
};

export default HolidayComponent;
