// Notifications.tsx
import Title from 'antd/es/typography/Title';
import { notificationApi } from 'Api/notification';
import { Loader } from 'components/Global';
import Buttons from 'components/Global/Buttons';
import SearchComponent from 'components/Global/SearchComponent';
import { useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import AddPushNotificationModal from '../addPushNotifcationApprove';
import DeletePushNotificationModal from '../deletePushNotificationApprove';
import EmployeeNotificationModal from '../EmployeeAndTimeSheetNotifications';
import styles from './index.module.scss';

// Custom debounce hook

type Props = {
	onClose: () => void;
	open: boolean;
	notificationData: any[];
	loading: boolean;
	fetchAllNotifications: () => void;
	changePage: () => void;
	handleSearch: (value: any) => void;
	setNotifications: any;
	searchValue: string;
};

const Notifications = (props: Props) => {
	const {
		open,
		onClose,
		loading,
		fetchAllNotifications,
		changePage,
		handleSearch,
		notificationData: notifications,
		setNotifications,
		searchValue,
	} = props;

	const [loadingNotifications, setLoadingNotifications] = useState<
		Record<string, boolean>
	>({});
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [approvalId, setApprovalId] = useState<string | null>(null);
	const [notificationId, setNotificationId] = useState<string | null>(null);
	const [isDelete, setIsDelete] = useState(false);
	const [deleteNotificationModal, setDeleteNotificationModal] =
		useState(false);
	const [employeeNotificationModal, setEmployeeNotificationModal] =
		useState(false);

	const handleSearchApi = async (value: any) => {
		//search api
	};

	const handleNotificationAction = async (data: any) => {
		const notificationType = data.notification.notificationType;

		if (!notificationType) return null;

		if (notificationType === 'PUSH') {
			dismissNotification(data.id);
		} else if (notificationType === 'APPROVAL') {
			setApprovalId(data.notification.approvalId);
			setNotificationId(data.id);
			if (
				(data.notification.moduleName === 'EMPLOYEE' ||
					data.notification.moduleName === 'SALARY' ||
					data.notification.moduleName === 'TAXATION') &&
				!data.notification.isDelete
			) {
				setEmployeeNotificationModal(true);
			} else if (data.notification.moduleName === 'TIME_SHEETS') {
				data.notification.isDelete && setIsDelete(true);
				setEmployeeNotificationModal(true);
			} else {
				if (data.notification.isDelete) {
					setDeleteNotificationModal(true);
				} else {
					setNotificationModalOpen(true);
				}
			}
			onClose();
		}
	};

	const dismissNotification = async (id: string) => {
		setLoadingNotifications((prev) => ({ ...prev, [id]: true }));
		try {
			await notificationApi.dismissNotification({
				userNotificationId: id,
			});
			setNotifications((prevState: any) => ({
				...prevState,
				data: prevState.data.filter(
					(notification: any) => notification.id !== id
				),
				totalCount: prevState.totalCount - 1,
			}));

			toastText('Notification dismissed successfully', 'success');
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in dismiss';
			toastText(message, 'error');
		} finally {
			setLoadingNotifications((prev) => ({ ...prev, [id]: false }));
		}
	};

	const renderActionButton = (data: any) => {
		const notificationType = data.notification.notificationType;
		if (!notificationType) return null;

		let action = '';
		if (notificationType === 'PUSH') {
			action = 'dismiss';
		} else if (notificationType === 'APPROVAL') {
			action = 'approve';
		}

		const buttonText = {
			dismiss: 'Dismiss',
			approve: 'View more',
		}[action];

		if (invalidText(buttonText)) return null;

		return (
			<Buttons
				buttons={[
					{
						text: buttonText!,
						isLoading: loadingNotifications[data.id] || false,
						className: 'btn-blue',
						isSubmit: false,
						disabled: false,
						onclick: () => {
							handleNotificationAction(data);
						},
						fontSize: '1.5rem',
						minWidth: '7rem',
						minHeight: '2rem',
					},
				]}
			/>
		);
	};

	// Using debouncedSearchValue

	return (
		<>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<div className={styles['header-content']}>
						<Title level={4}>Notifications</Title>
						<span
							className={styles['show-all']}
							onClick={() => changePage()}
						>
							Show More
						</span>
					</div>
				</div>
				<div className={styles['modal-body']}>
					<SearchComponent
						placeHolder={'Search here...'}
						handleChange={handleSearch}
						handleChangeApi={handleSearchApi}
						value={searchValue}
					/>
					{loading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: '2rem',
							}}
						>
							<Loader />
						</div>
					) : (
						<div
							className={styles['modal-body--notification-list']}
						>
							{notifications?.map((notification) => (
								<div
									key={notification.id}
									className={
										styles[
											'modal-body--notification-list__item'
										]
									}
								>
									<div
										className={`${styles['status-dot']} ${
											styles[
												notification.notification
													.notificationType
											]
										} ${
											styles[
												notification.notification
													.isDelete
													? 'delete'
													: ''
											]
										}`}
									/>
									<div className={styles['content']}>
										<div
											className={styles['content-header']}
										>
											<span className={styles['title']}>
												{
													notification.notification
														.title
												}
											</span>
										</div>
										<div className={styles['meta']}>
											<StatusDisplay
												messageProp={JSON.parse(
													notification.notification
														.message
												)}
											/>
										</div>
										<div className={styles['action']}>
											{renderActionButton(notification)}
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>

			{notificationModalOpen && (
				<AddPushNotificationModal
					isNotificationModalOpen={notificationModalOpen}
					handleCancel={() => {
						setNotificationModalOpen(false);
					}}
					approvalId={approvalId}
					fetchAllNotifications={fetchAllNotifications}
					notificationId={notificationId}
				/>
			)}
			{deleteNotificationModal && (
				<DeletePushNotificationModal
					isDeleteNotificationModalOpen={deleteNotificationModal}
					handleCancel={() => {
						setDeleteNotificationModal(false);
					}}
					approvalId={approvalId}
					notificationId={notificationId}
					fetchAllNotifications={fetchAllNotifications}
				/>
			)}
			{employeeNotificationModal && (
				<EmployeeNotificationModal
					isNotificationModalOpen={employeeNotificationModal}
					handleCancel={() => {
						setEmployeeNotificationModal(false);
					}}
					approvalId={approvalId}
					isDelete={isDelete}
					notificationId={notificationId}
					fetchAllNotifications={fetchAllNotifications}
				/>
			)}
		</>
	);
};

export default Notifications;

const StatusDisplay = ({ messageProp }: any) => {
	const {
		createdAt,
		createdBy,
		eventType,
		approvedBy,
		rejectedBy,
		deletedBy,
	} = messageProp;

	// Format date
	const date = new Date(createdAt);
	const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(
		date.getMonth() + 1
	)
		.toString()
		.padStart(2, '0')}/${date.getFullYear()}`;

	// Format time
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12; // Convert 0 to 12
	const formattedTime = `${formattedHours}:${minutes
		.toString()
		.padStart(2, '0')} ${ampm}`;

	return (
		<div className={styles['notification_message']}>
			{eventType === 'ADD' && <span>Added by </span>}
			{eventType === 'EDIT' && <span>Edited by </span>}
			{eventType === 'DELETE' && <span>Deleted by </span>}
			<span>{createdBy} </span>
			<span>on </span>
			<span>{formattedDate} </span>
			<span>at </span>
			<span>{formattedTime} </span>
			{approvedBy && (
				<>
					<span>Approved by </span>
					<span>{approvedBy}</span>
				</>
			)}
			{rejectedBy && (
				<>
					<span>Rejected by </span>
					<span>{rejectedBy}</span>
				</>
			)}
			{deletedBy && (
				<>
					<span>Deleted by </span>
					<span>{deletedBy}</span>
				</>
			)}
		</div>
	);
};
