import { SearchOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';

const statusOptions = [
	{
		label: 'All Status',
		value: '',
	},
	{
		label: 'Active',
		value: 'ACTIVE',
	},
	{
		label: 'Suspended',
		value: 'SUSPENDED',
	},
	{
		label: 'Redundant',
		value: 'REDUNDANT',
	},
	{
		label: 'Retired',
		value: 'RETIRED',
	},
	{
		label: 'Reassigned',
		value: 'REASSIGNED',
	},
	{
		label: 'Dismissed',
		value: 'DISMISSED',
	},
	{
		label: 'Deceased',
		value: 'DECEASED',
	},
];

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	// supervisorFilterValue: string;
	// handleSupervisorFilter: (value: string) => void;
	// options: any;
	handleSearchApi: (value: string) => void;
};

const EmployeeHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		statusFilterValue,
		handleStatusFilter,
		// supervisorFilterValue,
		// options,
		// handleSupervisorFilter,

		handleSearchApi,
	} = props;

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search employee..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
			</Space>
			<Space>
				<Select
					// showSearch
					// optionFilterProp="label"
					className={styles['employee-header-item']}
					value={statusFilterValue}
					options={statusOptions}
					onChange={(value) => {
						handleStatusFilter(value);
					}}
					size="large"
					placeholder="Select Status"
				/>
			</Space>
			{/* <Select
				className={styles['employee-header-item']}
				value={supervisorFilterValue}
				options={options}
				onChange={(value) => {
					handleSupervisorFilter(value);
				}}
				size="large"
				placeholder="Select Supervisor"
			/> */}
		</div>
	);
};

export default EmployeeHeader;
