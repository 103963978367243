import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { CloseSvg } from 'utils/svgs';
import TimeSheetsTableHeader from './Table/Header/index';
import TimeSheetsTable from './Table/TimeSheetsTable';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import { timeSheetsApi } from 'Api/timeSheet';
import dayjs from 'dayjs';

const TimeSheetComponent = () => {
	const navigate = useNavigate();

	const [timeSheetData, setTimeSheetData] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const [searchValue, setSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [date, setDate] = useState<any>(dayjs());

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};
	const handleDatePicker = (value: any) => {
		setDate(value);
	};

	const handleSearchApi = async (value: string) => {
		// API call to search
	};

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchTimeSheets = async () => {
		try {
			setIsLoading(true);
			const timeSheets = await timeSheetsApi.getTimeSheets({
				month: Number(date['$M']) + 1,
				year: date['$y'],
			});

			setTimeSheetData(timeSheets.data.data);
			setTotalRecords(timeSheets.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching time sheets.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchTimeSheets();
	}, [date, searchValue, currentPage, pageSize, sortField, sortOrder]);

	return (
		<div className="modal-animation">
			<div className={styles['time-logs']}>
				<div className={styles['time-logs__header']}>
					<div className={styles['time-logs__header__title']}>
						Time Sheets
					</div>
					<div
						className={styles['time-logs__header__actions']}
						onClick={() => {
							navigate('/time-activities');
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={styles['time-logs__table']}>
					<TimeSheetsTableHeader
						searchValue={searchValue}
						handleSearch={handleSearch}
						handleSearchApi={handleSearchApi}
						handleDatePicker={handleDatePicker}
						dateValue={date}
					/>
					<TimeSheetsTable
						tableData={timeSheetData}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						tableChangeHandler={tableChangeHandler}
						isLoading={isLoading}
						fetchTimeSheets={fetchTimeSheets}
					/>
				</div>
			</div>
		</div>
	);
};

export default TimeSheetComponent;
